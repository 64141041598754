import {createAction, props} from '@ngrx/store';
import {IndirizzoUtente, UserGeolocation} from '../reducers/profile.reducer';

export const LoadProfile = createAction(
  '[Profile] Load Profile');

export const GetUserPosition = createAction(
  '[Profile] Get user Position');

export const GetGeolocationPositionAddress = createAction(
  '[Profile] Get Geolocation Position Address');

export const RequestGeolocationPermission = createAction(
  '[Profile] Request Geolocation Permission');

export const SetCurrentGeolocation = createAction(
  '[Profile] Set Current Geolocation',
  props<{ currentGeolocation: UserGeolocation }>());

export const ConfirmGeolocationPosition = createAction(
  '[Profile] Confirm Geolocation Position',
  props<{ addressResults: google.maps.GeocoderResult }>());

export const SetIndirizzoByGeolocationPosition = createAction(
  '[Profile] Set Indirizzo By Geolocation Position',
  props<{ indirizzoUtente: IndirizzoUtente }>());

export const SetIndirizzoByCurrentGeolocation = createAction(
  '[Profile] Set Indirizzo By Current Geolocation');

export const ConfirmSetIndrizzoUtente = createAction(
  '[Profile] Confirm Set Indirizzo Utente',
  props<{ indirizzoUtente: IndirizzoUtente }>());

export const SetIndirizzoSelezionato = createAction(
  '[Profile] Set Indirizzo Selezionato',
  props<{ indirizzoUtente: IndirizzoUtente }>());

export const ConfirmResetIndrizzoUtente = createAction(
  '[Profile] Confirm Reset Indirizzo Utente');

export const ResetIndrizzoUtente = createAction(
  '[Profile] Reset Indirizzo Utente');



