import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {NgxStripeModule} from 'ngx-stripe';
import {NgxPayPalModule} from 'ngx-paypal';

import {environment} from '../environments/environment';

import {CoreModule} from './core/core.module';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app/app.component';

import {registerLocaleData} from '@angular/common';
import localeIt from '@angular/common/locales/it';
import {LOCALE_ID} from '@angular/core';

registerLocaleData(localeIt, 'it');

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: ''
    }),
    AppRoutingModule,
    NgxStripeModule.forRoot(environment.stripe_public_key),
    NgxPayPalModule,

    CoreModule
  ],
  providers: [
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    {provide: LOCALE_ID, useValue: 'it'}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
