import { createAction, props } from '@ngrx/store';
import {Ordine} from '../../../../models/ordini.model';
import {Attivita} from '../../../../models/attivita.model';

export const LoadDetail = createAction(
    '[Ordini][Edit] Load Ordini',
    props<{ id: string}>()
);

export const LoadDetailSuccess = createAction(
    '[Ordini][Edit] Load Ordini Success',
    props<{ ordine: Ordine, attivita: Attivita}>()
);

export const LoadDetailError = createAction(
    '[Ordini][Edit] Load Ordini Error'
);

export const ResetOrdineDetail = createAction(
    '[Ordini][Edit] Reset'
);

export const SetEdited = createAction(
    '[Ordini][Edit] Set Edited',
    props<{ edited: boolean}>()
);

export const Update = createAction(
    '[Ordini][Edit] Update Ordine',
    props<{ formData: Ordine}>()
);

export const UpdateSuccess = createAction(
    '[Ordini][Edit] Update Ordine Success',
    props<{ ordine: Ordine}>()
);

export const UpdateError = createAction(
    '[Ordini][Edit] Update Ordine Error',
    props<{ error: any}>()
);

export const StartSocketOrdini = createAction('[Ordini][Edit] Start Socket Ordini');

export const StopSocketOrdini = createAction('[Ordini][Edit] Stop Socket Ordini');

export const ChangeStatoOrdineSocket = createAction('[Ordini][Edit] Change Stato Socket Ordini',
  props<{ ordine: Partial<Ordine>}>()
);
