import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';
import {GeolocationPosition, Plugins} from '@capacitor/core';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';

const {Geolocation} = Plugins;

import {catchError, debounceTime, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';

import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {NotificationService} from '../../../../shared/services/notification.service';

import {AppState, DoNothingAction, selectProfileState} from '../../../core.state';
import {IndirizzoUtente, UserGeolocation} from '../reducers/profile.reducer';

import {ProfileActions} from '../actions';
import {AuthActions} from '../../../auth/store/actions';
import {CartActions} from '../../../cart/store/actions';

import {ProfileSelectors} from '../selectors';
import {CartSelectors} from '../../../cart/store/selectors';

// Posizione fittizia nelle vicinanze uscita spoltore utilizzata quando vengono negati i permessi di geolocalizzazione
// Ma vengono utilizzati dalla finestra scegli indirizzo per dare un bound dell'area generico per le funzioni di autocompletamento
export const DEFAULT_GEOLOCATION_BOUNDS_POSITION: UserGeolocation = {
  lat: 42.4571437469419,
  lng: 14.190618611745059
};

export const PROFILE_KEY = 'PROFILE';

@Injectable()
export class ProfileEffects {

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private alertController: AlertController,
    private translateService: TranslateService,
    private notificationService: NotificationService,
  ) {
  }

  initGeolocationPositionOnStart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROOT_EFFECTS_INIT),
        debounceTime(2500),
        withLatestFrom(
          this.store.pipe(select(ProfileSelectors.SelectCurrentGeolocation)),
          this.store.pipe(select(ProfileSelectors.SelectIndirizzoProfiloSelezionato))
        ),
        filter(([, currentGeolocation, indirizzo]) =>
          (!currentGeolocation?.lat && !currentGeolocation?.lng) || (!indirizzo.formattedString)),
        map(() => ProfileActions.GetUserPosition())
      )
  );

  getUserPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.GetUserPosition),
        switchMap(() => Geolocation.getCurrentPosition()
          .then((location: GeolocationPosition) => ({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          }))
          .catch(() => {
          })
        ),
        filter((currentGeolocation) => !!currentGeolocation && currentGeolocation.hasOwnProperty('lat')),
        map((currentGeolocation: UserGeolocation) => {
          return ProfileActions.SetCurrentGeolocation({currentGeolocation});
        }),
        catchError(() => EMPTY)
      )
  );

  onSetCurrentGeolocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.SetCurrentGeolocation),
        withLatestFrom(this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato)),
        filter(([, indirizzoSelezionato]) => (!indirizzoSelezionato.formattedString)),
        map(() => ProfileActions.GetGeolocationPositionAddress())
      )
  );

  setIndirizzoByCurrentGeolocation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.SetIndirizzoByCurrentGeolocation),
        switchMap(() => Geolocation.getCurrentPosition().then(
          (location: GeolocationPosition) => ({
            lat: location.coords.latitude,
            lng: location.coords.longitude,
          })).catch(() => {
            return {} as IndirizzoUtente;
          })
        ),
        filter((location) => !!location && location.hasOwnProperty('lat')),
        withLatestFrom(this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato)),
        map(([location, indirizzoSelezionato]) => { // Controlla che la location non sia quella già esistente
          if (!!location && location?.lat === indirizzoSelezionato?.lat && location?.lng === indirizzoSelezionato?.lng) {
            this.notificationService.info('L\'indirizzo rilevato è già impostato!');
            return {} as IndirizzoUtente;
          }
          return location;
        }),
        filter((location) => !!location && location.hasOwnProperty('lat')),
        map((location: IndirizzoUtente) => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            {location},
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.store.dispatch(ProfileActions.ConfirmGeolocationPosition({addressResults: results[0]}));
              }
            }
          );
        }),
        catchError(() => EMPTY)
      ), {dispatch: false}
  );

  confirmResetIndirizzoUtente$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProfileActions.ConfirmResetIndrizzoUtente,
          ProfileActions.ConfirmSetIndrizzoUtente
        ),
        withLatestFrom(
          this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato),
          this.store.select(CartSelectors.SelectTotProductsInCart)
        ),
        map(([action, indirizzoSelezionato, numTotProductsInCart]: [any, IndirizzoUtente, number]) => {

          let resetCart = false;
          let message = null;
          let indirizzoUtente: IndirizzoUtente = null;

          switch (action.type) {
            case '[Profile] Confirm Reset Indirizzo Utente':
              message = `<br>Sei sicuro di voler resettare il tuo indirizzo di consegna?`;
              break;
            case '[Profile] Confirm Set Indirizzo Utente':
              indirizzoUtente = action?.indirizzoUtente;
              if (!!indirizzoSelezionato.formattedString && indirizzoUtente.lat !== indirizzoSelezionato.lat && indirizzoUtente.lng !== indirizzoSelezionato.lng) {
                message = `<br>Sei sicuro di voler modificare l'indirizzo di consegna?`;
              }
              break;
          }

          if (!!message && numTotProductsInCart > 0) {
            message += ' L\'ordine in corso verrà annullato.';
            resetCart = true;
          }

          return {
            message,
            resetCart,
            indirizzoUtente
          };
        }),
        switchMap(({message, resetCart, indirizzoUtente}) => {
          if (!message) {
            return of({role: 'ok', data: {message, resetCart, indirizzoUtente}} as any);
          } else {
            return this.alertController.create({
              header: 'Attenzione',
              message,
              buttons: [
                {
                  text: this.translateService.instant('move.label.si'),
                  role: 'ok',
                  handler: () => ({resetCart, indirizzoUtente})
                },
                {
                  text: 'No', role: 'cancel'
                }
              ]
            }).then((alert) => {
              alert.present();
              return alert.onWillDismiss();
            });
          }
        }),
        filter((res: any) => res.role !== 'cancel'),
        map((res) => {

          if (res.data?.resetCart) {
            this.store.dispatch(CartActions.ResetCart());
          }

          if (!res?.data?.indirizzoUtente) {
            return ProfileActions.ResetIndrizzoUtente();
          } else {
            return ProfileActions.SetIndirizzoSelezionato({indirizzoUtente: res.data.indirizzoUtente});
          }
        })
      )
  );

  requestGeolocationPermission$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProfileActions.RequestGeolocationPermission,
        ),
        switchMap(() => Geolocation.requestPermissions()
          .then((permission) => {
            return DoNothingAction();
          })
          .catch((err) => DoNothingAction())
        )
      )
  );

  /**
   * Dalla posizione dell'utente ricava l'indirizzo da impostare nel profilo come selezionato
   */
  getGeolocationPositionAddress$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.GetGeolocationPositionAddress),
        withLatestFrom(this.store.pipe(select(ProfileSelectors.SelectCurrentGeolocation))),
        filter(([action, currentGeolocation]) => (!!currentGeolocation.lat && !!currentGeolocation.lng)),
        map(([action, currentGeolocation]) => {
          const geocoder = new google.maps.Geocoder();
          geocoder.geocode(
            {location: currentGeolocation},
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
              if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                this.store.dispatch(ProfileActions.ConfirmGeolocationPosition({addressResults: results[0]}));
              }
            }
          );
        })
      ), {dispatch: false}
  );

  /**
   * Chiede conferma e imposta effetivamente i dati dell'indirizzo trovato
   * ogni volta che imposto l'indirizzo vado anche a resettare il carrello
   */
  confrimGeolocationPosition$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProfileActions.ConfirmGeolocationPosition),
        withLatestFrom(
          this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato),
          this.store.select(CartSelectors.SelectTotProductsInCart)
        ),
        map(([{addressResults}, indirizzoSelezioanto, numTotProductsInCart]) => {

          const newIndirizzo = {
            lat: addressResults.geometry.location.lat(),
            lng: addressResults.geometry.location.lng(),
            formattedString: addressResults.formatted_address
          } as IndirizzoUtente;

          let resetCart = false;
          let message = `<br><b>${newIndirizzo?.formattedString}</b><br><br>Impostarlo come indirizzo di consegna?`;
          if (numTotProductsInCart > 0 && indirizzoSelezioanto?.lat !== newIndirizzo?.lat && indirizzoSelezioanto?.lng !== newIndirizzo?.lng) {
            message += ' L\'ordine in corso verrà annullato.';
            resetCart = true;
          }

          return {
            indirizzoUtente: newIndirizzo,
            message,
            resetCart
          };
        }),
        switchMap(({indirizzoUtente, message, resetCart}) => this.alertController.create({
            header: 'Posizione rilevata',
            message,
            buttons: [
              {
                text: this.translateService.instant('move.label.si'),
                role: 'ok',
                handler: () => {
                  if (resetCart) {
                    this.store.dispatch(CartActions.ResetCart());
                  }
                  this.store.dispatch(ProfileActions.ConfirmSetIndrizzoUtente({indirizzoUtente}));
                }
              },
              {
                text: 'No', role: 'cancel'
              }
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        )
      ), {dispatch: false}
  );

  persistData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProfileActions.SetCurrentGeolocation,
          ProfileActions.ResetIndrizzoUtente,
          ProfileActions.SetIndirizzoSelezionato
        ),
        withLatestFrom(this.store.pipe(select(selectProfileState))),
        tap(([action, state]) =>
          this.localStorageService.setItem(PROFILE_KEY, state)
        )
      ),
    {dispatch: false}
  );

  logoutAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap((action) => this.localStorageService.removeItem(PROFILE_KEY))
      ), {dispatch: false}
  );

}
