import {
  ActionReducerMap, createAction,
  createFeatureSelector,
  MetaReducer
} from '@ngrx/store';

import {authReducer, AuthState} from './auth/store/reducers/auth.reducer';
import {initStateFromLocalStorage} from './meta-reducers/init-state-from-local-storage.reducer';
import {cartReducer, CartState} from './cart/store/reducers/cart.reducer';
import {profileReducer, ProfileState} from './profile/store/reducers/profile.reducer';
import {routerReducer, RouterReducerState} from '@ngrx/router-store';
import {RouterStateUrl} from './router/router.state';
import {configsReducer, ConfigsState} from './configs/store/reducers/configs.reducer';

export const reducers: ActionReducerMap<AppState> = {
  router: routerReducer,
  auth: authReducer,
  cart: cartReducer,
  profile: profileReducer,
  configs: configsReducer
};

export const metaReducers: MetaReducer<AppState>[] = [
  initStateFromLocalStorage
];

export const selectRouterState = createFeatureSelector<AppState, RouterReducerState<RouterStateUrl>>('router');
export const selectAuthState = createFeatureSelector<AppState, AuthState>('auth');
export const selectCartState = createFeatureSelector<AppState, CartState>('cart');
export const selectProfileState = createFeatureSelector<AppState, ProfileState>('profile');
export const selectConfigsState = createFeatureSelector<AppState, ConfigsState>('configs');

export interface AppState {
  router: RouterReducerState<RouterStateUrl>;
  auth: AuthState;
  cart: CartState;
  profile: ProfileState;
  configs: ConfigsState;
}

export const DoNothingAction = createAction(
  '[App] DoNothing'
);

