import {createReducer, on, Action} from '@ngrx/store';
import {AuthActions} from '../../../auth/store/actions';
import {ConfigsActions} from '../actions';

export interface ConfigsState {
  areeConsegna: any[];
}

/*
 INITIAL STATES
 */

export const initialState: ConfigsState = {
  areeConsegna: []
};

const reducer = createReducer(
  initialState,

  on(ConfigsActions.LoadAreeConsegnaSuccess, (state, {areeconsegna}) => ({
    ...state,
    areeConsegna: areeconsegna
  })),

  on(AuthActions.Logout, state => ({
    ...state,
    ...initialState
  }))
);

export function configsReducer(
  state: ConfigsState | undefined,
  action: Action
): ConfigsState {
  return reducer(state, action);
}
