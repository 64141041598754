import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {MyApiProvider} from '../../../shared/abstract/my.api';
import {Ordine} from '../../../models/ordini.model';
import {catchError, map} from 'rxjs/operators';
import {Observable, of, throwError} from 'rxjs';
import {Attivita} from '../../../models/attivita.model';
import {FasceOrarieUtility} from '../../../shared/utility/fasce-orarie.utility';

import * as moment from 'moment';
import {PaymentIntent} from '@stripe/stripe-js';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CartService extends MyApiProvider {

  constructor(public http: HttpClient) {
    super(http, '/ordinidelivery');
  }

  confermaOrdine(ordine: Ordine): Observable<Ordine> {
    return this.post(ordine).pipe(
      map((res: Ordine) => res),
      catchError(error => throwError(error))
    );
  }

  inviaPagamento(idOrdineMapping: string, datiDaAggiornare: any): Observable<Ordine> {
    return this.patch(idOrdineMapping, datiDaAggiornare, '/payment').pipe(
      map((res: Ordine) => res),
      catchError(error => throwError(error))
    );
  }

  getAttivitaByLocation(params: { id: string, lat: number, lng: number }): Observable<Attivita | null> {
    return this.get(params, '/attivita/m/single-by-location').pipe(
      map((attivita: Attivita) => ({
        ...attivita,
        InfoTemporaliAttivita: FasceOrarieUtility.getInfoTemporaliAttivita(attivita)
      })),
      catchError(() => of(null))
    );
  }

  validazioneOrdinePerConferma(oldAttivita: Attivita, newAttivita: Attivita, ordineInCorso: Partial<Ordine>, isPreOrdine: boolean): Observable<boolean> {

    /*console.log('ordine', ordineInCorso);
    console.log('old', oldAttivita);
    console.log('new', newAttivita);*/

    if (!newAttivita || !ordineInCorso) {
      return of(false);
    }

    // Delivery disattivato in questo momento o problemi di stato con l'attività
    if (!newAttivita.DeliveryOn || !newAttivita.stato || oldAttivita.id !== newAttivita.id) {
      return of(false);
    }

    // Il preordine non è più possibile perchè in questo momento è disattivato
    if (isPreOrdine && !newAttivita.InfoTemporaliAttivita.PreOrdinePossibile) {
      return of(false);
    }

    // Il costo di consegna è stato modificato
    if (oldAttivita.CostoDiConsegna !== newAttivita.CostoDiConsegna) {
      return of(false);
    }

    // l'utente ha cambiato la propria posizione e quindi vanno ricalcolati i dati
    if (oldAttivita.CodicePosizioneUtente !== newAttivita.CodicePosizioneUtente) {
      return of(false);
    }

    // Cambiata la fascia oraria attiva al momento della conferma se non è in pre ordine deve essere uguale per forza
    if (!isPreOrdine && oldAttivita.InfoTemporaliAttivita.ArcoTemporale !== newAttivita.InfoTemporaliAttivita.ArcoTemporale) {
      return of(false);
    }

    // const now = moment('2021-03-23 23:15', 'YYYY-MM-DD HH:mm');
    const now = moment();
    const orarioFineFasciaOraria = oldAttivita.InfoTemporaliAttivita.ArcoTemporale.split('-')[1];
    const dataFineFascia = moment(`${ordineInCorso.DataConsegna} ${orarioFineFasciaOraria}`, 'YYYY-MM-DD HH:mm');

    /* console.log(now);
     console.log(dataFineFascia);*/

    if (now.isAfter(dataFineFascia)) {
      return of(false);
    }

    return of(true);
  }

  // STRIPE PAYMENT

  createPaymentIntent(orderId: string): Observable<PaymentIntent> {
    return this.http.post<PaymentIntent>(
      `${environment.apiUrl}/v1/payment-intent`,
      {id: orderId}
    ).pipe(
      map((res: PaymentIntent) => res)
    );
  }

}
