import {Injectable} from '@angular/core';
import {environment as ENV} from '../../../environments/environment';

const APP_PREFIX = ENV.localStoragePrefix;

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    constructor() {
    }

    static loadInitialState() {
        return Object.keys(localStorage).reduce((state: any, storageKey) => {
            if (storageKey.includes(APP_PREFIX)) {
                const stateKeys = storageKey
                    .replace(APP_PREFIX, '')
                    .toLowerCase()
                    .split('.')
                    .map(key =>
                        key
                            .split('-')
                            .map((token, index) =>
                                index === 0
                                    ? token
                                    : token.charAt(0).toUpperCase() + token.slice(1)
                            )
                            .join('')
                    );
                let currentStateRef = state;
                stateKeys.forEach((key, index) => {
                    if (index === stateKeys.length - 1) {
                        currentStateRef[key] = JSON.parse(localStorage.getItem(storageKey));
                        return;
                    }
                    currentStateRef[key] = currentStateRef[key] || {};
                    currentStateRef = currentStateRef[key];
                });
            }
            return state;
        }, {});
    }

    setItem(key: string, value: any) {
        localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
    }

    getItem(key: string) {
        return JSON.parse(localStorage.getItem(`${APP_PREFIX}${key}`));
    }

    /**
     * Questa funzione serve per salvare aggiornare un elemnto del local storage che se non esiste viene creato,
     * mentre se esiste vengono aggiornati solo gli elementi passati nel value
     * @param key
     * @param value
     * @param initialState
     */
    patchItem(key: string, value: any, initialState) {
        const savedState = this.getItem(key);
        localStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify((!savedState) ? {...initialState, ...value} : {...savedState, ...value}));
    }

    removeItem(key: string) {
        localStorage.removeItem(`${APP_PREFIX}${key}`);
    }

    /** Tests that localStorage exists, can be written to, and read from. */
    testLocalStorage() {
        const testValue = 'testValue';
        const testKey = 'testKey';
        let retrievedValue: string;
        const errorMessage = 'localStorage did not return expected value';

        this.setItem(testKey, testValue);
        retrievedValue = this.getItem(testKey);
        this.removeItem(testKey);

        if (retrievedValue !== testValue) {
            throw new Error(errorMessage);
        }

        console.log('Test Local storage OK END');
    }
}
