import {Injectable} from '@angular/core';
import {Plugins} from '@capacitor/core';
import {ActivationEnd, NavigationEnd, Router} from '@angular/router';
import {AlertController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {select, Store} from '@ngrx/store';
import {ofType, createEffect, Actions, ROOT_EFFECTS_INIT} from '@ngrx/effects';

const {SplashScreen} = Plugins;

import {environment as ENV} from '../../../../../environments/environment';

import {of} from 'rxjs';
import {catchError, debounceTime, filter, map, pairwise, switchMap, take, tap, withLatestFrom} from 'rxjs/operators';

import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {AuthService} from '../../service/auth.service';
import {NotificationService} from '../../../../shared/services/notification.service';
import {FcmService} from '../../../services/fcm.service';

import {AppState, selectAuthState} from '../../../core.state';
import {AuthActions} from '../actions';
import {initiaFormDataLogin, initialState, initialUserAuthData} from '../reducers/auth.reducer';
import {CartSelectors} from '../../../cart/store/selectors';
import {selectRouter} from '../../../router/route.selectors';
import {AuthSelectors} from '../selectors';

export const AUTH_KEY = 'AUTH';
export const VERSIONE_KEY = 'VERSIONE_APP';

@Injectable()
export class AuthEffects {

  currentUrl;
  previousUrl;

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private router: Router,
    private store: Store<AppState>,
    private service: AuthService,
    private alertController: AlertController,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private fcmService: FcmService,
  ) {

    // Serve a salvare il prevUrl
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(({urlAfterRedirects}: NavigationEnd) => {
        this.previousUrl = this.currentUrl;
        this.currentUrl = urlAfterRedirects;
        this.store.dispatch(AuthActions.SetPrevUrl({url: this.previousUrl}));
      });
  }

  TokenCheck = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ROOT_EFFECTS_INIT,
          AuthActions.InitCheckToken
        ),
        withLatestFrom(this.store.select(selectAuthState)),
        tap(() => this.store.dispatch(AuthActions.ControlloVersioneApp())),
        map(([action, authState]) => {

          this.translateService.setDefaultLang('it');
          this.translateService.use('it');

          // se non ero già autenticato non c'è niente da controllare
          if (!authState.isAuthenticated) {
            return AuthActions.InitCheckTokenNull();
          }

          return AuthActions.InitCheckTokenSuccess();
        })
      )
  );

  InitTokenSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.InitCheckTokenSuccess),
        tap((action) => {
          // aggiorno il token delle notifiche se presente
          // this.fcmService.initPush();
        }),
        debounceTime(500),
        tap(() => {
          SplashScreen.hide();
        })
      ), {dispatch: false}
  );

  InitTokenNull = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.InitCheckTokenNull),
        debounceTime(500),
        tap((action) => {
          SplashScreen.hide();
        })
      ), {dispatch: false}
  );

  ControlloVersioneApp = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.ControlloVersioneApp),
        map(action => {
            const savedVers = this.localStorageService.getItem(VERSIONE_KEY);
            if (!savedVers || (savedVers !== ENV.appVersion)) {
              this.localStorageService.setItem(VERSIONE_KEY, ENV.appVersion);
              this.notificationService.default(`App Aggiornata ver. ${ENV.appVersion}`);
              return AuthActions.Logout();
            } else {
              return AuthActions.ControlloVersioneAppNoChange();
            }
          }
        )
      )
  );

  updateTokenNotifiche = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.UpdateTokenNotifichePush),
        switchMap((action) => this.fcmService.registerTokenToServer(action.token).pipe(
          map(() => AuthActions.UpdateTokenNotifichePushSuccess()),
          catchError(error => of(AuthActions.UpdateTokenNotifichePushError()))
        ))
      )
  );

  signIn$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          AuthActions.SignIn,
        ),
        switchMap(({userData}) => this.service.registerUser(userData).pipe(
          map((user) => AuthActions.SignInSuccess({userData: user})),
          catchError((error) => of(AuthActions.SignInError({error})))
        ))
      )
  );

  /*** Action sul pulsante 'Accedi' nella LoginPage ***/
  loginMain = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Login),
        debounceTime(500),
        tap((action) => {
          const paramsLogin = {...action.formDataLogin};
          this.store.dispatch(AuthActions.MemorizzaCredenzialiLogin({formDataLogin: paramsLogin}));
        }),
        switchMap((action) => this.service.loginUser({
          username: action.formDataLogin.username,
          password: action.formDataLogin.password
        }).pipe(
          map(({userAuthData}) => {
            return AuthActions.LoginSuccess({userAuthData});
          }),
          catchError((error) => of(AuthActions.LoginError({error})))
        ))
      )
  );

  loginSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginSuccess),
        tap(({userAuthData}) => this.localStorageService.patchItem(AUTH_KEY, {
          isAuthenticated: true,
          token: userAuthData.token,
          pushToken: userAuthData.PushToken,
          userAuthData
        }, initialState)),
        withLatestFrom(this.store.select(AuthSelectors.SelectPrevUrl)),
        tap(([, prevUrl]) => {
          // this.fcmService.initPush();
          this.router.navigateByUrl(prevUrl, {replaceUrl: true});
        })
      ), {dispatch: false}
  );

  loginError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.LoginError),
        debounceTime(500),
        tap(() => {
          // this.loadingService.stopLoading();
        })
      ),
    {dispatch: false}
  );

  memorizzaCredenzialiLogin = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.MemorizzaCredenzialiLogin),
        map(action => action.formDataLogin),
        withLatestFrom(this.store.pipe(select(selectAuthState))),
        tap(([formDataLogin, authState]) => {
          if (formDataLogin.memorizzaCredenziali) {
            this.localStorageService.patchItem(AUTH_KEY, {
              formDataLogin
            }, authState);
          } else {
            this.localStorageService.patchItem(AUTH_KEY, {
              formDataLogin: initiaFormDataLogin
            }, authState);
          }
        })
      ),
    {dispatch: false}
  );

  alertLogout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AlertLogout),
        switchMap(() => this.alertController.create({
            header: this.translateService.instant('move.label.warning'),
            message: this.translateService.instant('move.alert.message.alert-logout'),
            buttons: [
              {
                text: this.translateService.instant('move.label.si'), role: 'ok',
                handler: (value) => {
                  this.store.dispatch(AuthActions.Logout());
                }
              },
              {
                text: 'No', role: 'cancel'
              }
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        )
      ),
    {dispatch: false}
  );

  logout = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap(() => {
          this.localStorageService.patchItem(AUTH_KEY, {
            isAuthenticated: false,
            userAuthData: initialUserAuthData
          }, initialState);
          this.router.navigate(['/home'], {replaceUrl: true});
          SplashScreen.hide();
        })
      ),
    {dispatch: false}
  );

}
