import {Injectable} from '@angular/core';
import {MyApiProvider} from '../../../shared/abstract/my.api';
import {HttpClient} from '@angular/common/http';

import {environment as ENV} from '../../../../environments/environment';
import {LoginFormData, UserAuthData} from '../store/reducers/auth.reducer';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends MyApiProvider {

  constructor(public http: HttpClient) {
    super(http);
  }

  loginUser(user: LoginFormData | any): Observable<{ userAuthData: UserAuthData }> {
    return this.http.post<any>(ENV.authUrl, user).pipe(
      map(res => {

        const userAuthData = {...res};

        return {
          userAuthData
        };
      })
    );
  }

  checkUserEmail(email: string): Observable<boolean> {
    return this.get({email}, '/userspublic/m/check-email').pipe(
      map((res) => res),
    );
  }

  registerUser(user: any): Observable<{ userData: any }> {
    return this.post(user, '/userspublic').pipe(
      map(res => ({
        userData: {...res.data}
      }))
    );
  }

}
