import {Params} from '@angular/router';
import {createSelector} from '@ngrx/store';
import {RouterReducerState} from '@ngrx/router-store';
import {selectRouterState} from '../core.state';

export interface RouterStateUrl {
  url: string;
  params: Params;
  queryParams: Params;
}

export const SelectCurrentUrl = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => state.state.url
);

export const SelectCurrentUrlParams = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => state.state.params
);

export const SelectCurrentUrlQueryParams = createSelector(
  selectRouterState,
  (state: RouterReducerState<RouterStateUrl>) => state.state.queryParams
);
