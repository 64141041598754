import { createSelector } from '@ngrx/store';

import {selectProfileState} from '../../../core.state';
import {ProfileState} from '../reducers/profile.reducer';

export const SelectLoading = createSelector(
    selectProfileState,
    (state: ProfileState) => state.loading
);

export const SelectCurrentGeolocation = createSelector(
  selectProfileState,
  (state: ProfileState) => state.currentGeolocation
);

export const SelectIndirizzoProfiloSelezionato = createSelector(
  selectProfileState,
  (state: ProfileState) => state.indirizzoSelezionato
);

export const SelectIndirizzoSelezionatoCoordinates = createSelector(
  selectProfileState,
  (state: ProfileState) => ({
    lat: state.indirizzoSelezionato.lat,
    lng: state.indirizzoSelezionato.lng
  })
);

export const SelectMetodiDiPagamento = createSelector(
  selectProfileState,
  (state: ProfileState) => state.metodiDiPagamento
);

export const SelectIndirizzi = createSelector(
  selectProfileState,
  (state: ProfileState) => state.indirizzi
);

