import {createAction, props} from '@ngrx/store';
import {ProdottoCart} from '../../../../models/cart.model';
import {Attivita} from '../../../../models/attivita.model';
import {TotaliCarrello} from '../reducers/cart.reducer';
import {IndirizzoUtente} from '../../../profile/store/reducers/profile.reducer';
import {METODO_DI_PAGAMENTO_ORDINE, Ordine} from '../../../../models/ordini.model';

export const SetAttivitaOrdine = createAction(
  '[Cart] Set Attivita Ordine',
  props<{ attivita: Attivita }>());

export const SetLoading = createAction(
  '[Cart] Set Loading',
  props<{ loading: boolean }>());

export const ConfirmResetCart = createAction(
  '[Cart] Confirm Reset Cart');

export const ResetCart = createAction(
  '[Cart] Reset Cart');

export const InsertProduct = createAction(
  '[Cart] Insert Product',
  props<{ prodotto: ProdottoCart }>());

export const UpdateProduct = createAction(
  '[Cart] Update Product',
  props<{ prodotto: ProdottoCart }>());

export const DeleteProduct = createAction(
  '[Cart] Delete Product',
  props<{ id: string }>());

export const RefreshTotaliCart = createAction(
  '[Cart] Refresh Totali Cart');

export const SetTotaliCart = createAction(
  '[Cart] Set Totali Cart',
  props<{ totali: TotaliCarrello }>());

export const OpenEditProductModal = createAction(
  '[Cart] Open Edit Product Modal',
  props<{ prodotto: ProdottoCart }>());

export const ConfirmDeleteProduct = createAction(
  '[Cart] Confirm Delete Product',
  props<{ prodotto: ProdottoCart }>());

export const SetIndirizzoOrdine = createAction(
  '[Cart] Set Indirizzo Ordine',
  props<{ IndirizzoUtente: IndirizzoUtente }>());

export const OpenScegliMetodoDiPagamentoActionSheet = createAction(
  '[Cart] Open Scegli Metodo Pagamento Action Sheet');

export const SetMetodoDiPagamentoOrdine = createAction(
  '[Cart] Set Metodo Di Pagamento Ordine',
  props<{ MetodoDiPagamento: METODO_DI_PAGAMENTO_ORDINE }>());

export const OpenOrarioRichiestoPicker = createAction(
  '[Cart] Open Orario Richiesto Picker');

export const SetConsegnaPrimaPossibile = createAction(
  '[Cart] Set Consegna Prima Possibile Ordine',
  props<{  ConsegnaPrimaPossibile: boolean }>());

export const SetConsegnaPrimaPossibileSuccess = createAction(
  '[Cart] Set Consegna Prima Possibile Ordine Success',
  props<{ ConsegnaPrimaPossibile: boolean, OrarioRichiesto: string, DataConsegna: string }>());

export const SetOrarioRichiestoOrdine = createAction(
  '[Cart] Set Orario Richiesto Ordine',
  props<{ OrarioRichiesto: string}>());

export const SetOrarioRichiestoOrdineSuccess = createAction(
  '[Cart] Set Orario Richiesto Ordine Success',
  props<{ OrarioRichiesto: string, ConsegnaPrimaPossibile: boolean, DataConsegna: string}>());

export const ValidazioneOrdine = createAction(
  '[Cart] Validazione Ordine');

export const ValidazioneOrdineError = createAction(
  '[Cart] Validazione Ordine Error');

export const ConfermaOrdine = createAction(
  '[Cart] Conferma Ordine');

export const ConfermaOrdineSuccess = createAction(
  '[Cart] Conferma Ordine Success',
  props<{ ordine: Partial<Ordine>}>());

export const InviaOrdineAlServer = createAction(
  '[Cart] Invia Ordine al Server',
  props<{ DatiAggiuntiviOrdine: Partial<Ordine>}>());

export const InviaOrdineAlServerSuccess = createAction(
  '[Cart] Invia Ordine al Server Success',
  props<{ ordine: Ordine}>());

export const InviaOrdineAlServerError = createAction(
  '[Cart] Invia Ordine al Server Error',
  props<{ error: any}>());

export const SendPagamento = createAction(
  '[Cart] Send Pagamento',
  props<{ MetodoDiPagamento: METODO_DI_PAGAMENTO_ORDINE, idOrdine: string, paymentId: string}>());

export const SendPagamentoSuccess = createAction(
  '[Cart] Send Pagamento Success',
  props<{ ordine: Ordine}>());

export const SendPagamentoError = createAction(
  '[Cart] Send Pagamento Error');

export const LoadOrdineDaPagare = createAction(
  '[Cart] Load Ordine da Pagare',
  props<{ id: string}>());

export const LoadOrdineDaPagareSuccess = createAction(
  '[Cart] Load Ordine da Pagare Success',
  props<{ ordineDaPagare: Ordine}>());

export const LoadOrdineDaPagareError = createAction(
  '[Cart] Load Ordine da Pagare Error');






