import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType, ROOT_EFFECTS_INIT} from '@ngrx/effects';

import {catchError, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {of} from 'rxjs';

import {AppState, selectConfigsState} from '../../../core.state';
import {AuthActions} from '../../../auth/store/actions';

import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {ConfigsActions} from '../actions';
import {AreeconsegnaService} from '../../service/areeconsegna.service';

export const CONFIGS_KEY = 'CONFIGS';

@Injectable()
export class ConfigsEffects {

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private areeConsegnaService: AreeconsegnaService
  ) {
  }

  /*initConfigs$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ROOT_EFFECTS_INIT
        ),
        mergeMap(() => [
          ConfigsActions.LoadAreeConsegna()
        ])
      )
  );*/

  loadAreeConsegna$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ConfigsActions.LoadAreeConsegna,
        ),
        switchMap(() => this.areeConsegnaService.getList({}).pipe(
          map(({items}) => ConfigsActions.LoadAreeConsegnaSuccess({areeconsegna: items})),
          catchError((error) => of(ConfigsActions.LoadAreeConsegnaError({error})))
        ))
      )
  );

  persistData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(

        ),
        withLatestFrom(this.store.pipe(select(selectConfigsState))),
        tap(([action, state]) =>
          this.localStorageService.setItem(CONFIGS_KEY, state)
        )
      ),
    {dispatch: false}
  );

  logoutAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap((action) => this.localStorageService.removeItem(CONFIGS_KEY))
      ), {dispatch: false}
  );

}
