import {Injectable} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ActionSheetController, AlertController, ModalController, PickerController} from '@ionic/angular';
import {Router} from '@angular/router';

import * as moment from 'moment';

import {defer, Observable, of} from 'rxjs';
import {catchError, debounceTime, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {
  METODO_DI_PAGAMENTO_ORDINE_CONTANTI,
  METODO_DI_PAGAMENTO_ORDINE_PAYPAL,
  METODO_DI_PAGAMENTO_ORDINE_STRIPE,
  Ordine,
  ProdottoOrdine, STATO_ORDINE_INATTESA,
  STATO_ORDINE_NONPROCESSATO,
  STATOINT_ORDINE,
  TIPO_ORDINE_DELIVERY
} from '../../../../models/ordini.model';
import {ProdottoCart} from '../../../../models/cart.model';

import {AppState, DoNothingAction, selectCartState} from '../../../core.state';
import {AuthActions} from '../../../auth/store/actions';
import {CartActions, CartProductActions} from '../actions';
import {CartSelectors} from '../selectors';

import {LocalStorageService} from '../../../local-storage/local-storage.service';
import {CartService} from '../../service/cart.service';

import {EditProductCartModalPage} from '../../../../shared/modals/edit-product-cart-modal/edit-product-cart-modal-page.component';

import {TotaliCarrello} from '../reducers/cart.reducer';
import {ProfileSelectors} from '../../../profile/store/selectors';
import {SelectDimensioneOrdine} from '../selectors/cart.selectors';
import {AuthSelectors} from '../../../auth/store/selectors';
import {environment} from '../../../../../environments/environment';
import {OrdiniActions} from '../../../../features/tab-ordini/store/actions';
import {NotificationService} from '../../../../shared/services/notification.service';

export const CART_KEY = 'CART';

@Injectable()
export class CartEffects {

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private alertController: AlertController,
    private modalController: ModalController,
    private pickerController: PickerController,
    private actionSheetController: ActionSheetController,
    private router: Router,
    private cartService: CartService,
    private notificationService: NotificationService
  ) {
  }

  openProductModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.OpenEditProductModal,
        ),
        exhaustMap((action) => this.modalController.create({
          component: EditProductCartModalPage,
          cssClass: 'add-product-to-cart-modal',
          swipeToClose: true,
          componentProps: {
            prodotto: action.prodotto
          }
        }).then((modal) => {
          modal.present();
          return modal.onWillDismiss();
        })),
        filter((res) => res.hasOwnProperty('data') && res.data?.hasOwnProperty('prodotto')),
        map((res) => {
          if (res.data.prodotto.Quantita <= 0) {
            this.store.dispatch(CartActions.ConfirmDeleteProduct({prodotto: res.data.prodotto}));
            return null;
          } else {
            return {
              ...res.data.prodotto
            } as ProdottoCart;
          }
        }),
        filter((prodotto: ProdottoCart) => !!prodotto),
        withLatestFrom(this.store.select(CartSelectors.SelectAttivitaOrdine)),
        map(([prodotto, attivita]) => CartProductActions.HandleSetProduct({prodotto, attivita}))
      )
  );

  confirmDeleteProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.ConfirmDeleteProduct),
        switchMap((prodotto) => this.alertController.create({
            header: 'Attenzione',
            message: `Sicuro di voler eliminare il prodotto dal tuo ordine?`,
            buttons: [
              {
                text: 'Si', role: 'ok',
                handler: () => prodotto
              },
              {
                text: 'No', role: 'cancel'
              }
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        ),
        filter((res) => res.role === 'ok'),
        map((res) => CartActions.DeleteProduct({id: res.data.prodotto.id}))
      )
  );

  validazioneOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.ValidazioneOrdine),
        withLatestFrom(
          this.store.select(AuthSelectors.SelectIsAuthenticated),
        ),
        tap(([, isAuth]) => {
          if (!isAuth) {
            this.store.dispatch(CartActions.SetLoading({loading: false}));
            this.router.navigateByUrl('/login', {replaceUrl: true});
          }
        }),
        withLatestFrom(
          this.store.select(CartSelectors.SelectAttivitaOrdine),
          this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato),
        ),
        switchMap(([, attivita, indirizzoUtente]) => this.cartService.getAttivitaByLocation({
          id: attivita.id,
          lng: indirizzoUtente.lng,
          lat: indirizzoUtente.lat
        }).pipe(
          map((newAttivita) => ({
            oldAttivita: attivita,
            newAttivita
          })),
          catchError(() => {
            return of({
              oldAttivita: attivita,
              newAttivita: null
            });
          })
        )),
        withLatestFrom(
          this.store.select(CartSelectors.SelectOrdine),
          this.store.select(CartSelectors.SelectIsPreordine),
        ),
        switchMap(([{
          oldAttivita,
          newAttivita
        }, ordineInCorso, isPreOrdine]) => this.cartService.validazioneOrdinePerConferma(oldAttivita, newAttivita, ordineInCorso, isPreOrdine)),
        map((valid: boolean) => (!!valid) ? CartActions.ConfermaOrdine() : CartActions.ValidazioneOrdineError())
      )
  );

  validazioneOrdineError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.ValidazioneOrdineError),
        switchMap(() => this.alertController.create({
            header: 'Ordine scaduto',
            subHeader: 'Siamo spiacenti ma questo ordine non è più valido perchè scaduto, verrà quindi cancellato!',
            message: `Scegli il tuo locale e invia un nuovo ordine`,
            buttons: [
              {
                text: 'Ok', role: 'ok',
              }
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        ),
        map(() => {
          this.router.navigateByUrl('/home', {replaceUrl: true});
          return CartActions.ResetCart();
          // return DoNothingAction();
        })
      )
  );

  confermaOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.ConfermaOrdine,
        ),
        withLatestFrom(
          this.store.select(AuthSelectors.SelectIsAuthenticated),
          this.store.select(selectCartState),
          this.store.select(SelectDimensioneOrdine),
          this.store.select(AuthSelectors.SelectAuthUserData),
        ),
        tap(([, isAuth]) => {
          if (!isAuth) {
            this.store.dispatch(CartActions.SetLoading({loading: false}));
            this.router.navigateByUrl('/login', {replaceUrl: true});
          }
        }),
        filter(([, isAuth, cart]) => isAuth && !!cart.ordine.MetodoDiPagamento),
        debounceTime(300),
        map(([, , cart, Dimensione, userData]) => ({
          ...cart.ordine,
          TipoOrdine: TIPO_ORDINE_DELIVERY,
          ID_Attivita: cart.attivita.id,
          DataConsegna: cart.ordine.DataConsegna,
          ConsegnaPrimaPossibile: !!cart.ordine.ConsegnaPrimaPossibile,
          OrarioRichiesto: (cart.ordine.ConsegnaPrimaPossibile) ? 'ASAP' : cart.ordine.OrarioRichiesto,
          FasciaOraria: cart.attivita.InfoTemporaliAttivita.ArcoTemporale,
          IsPreOrdine: cart.isPreordine,
          CodiceAttivita: cart.attivita.CodiceAttivita,
          RiferimentoAttivita: cart.attivita.RagioneSociale,
          ID_Utente: userData.id,
          RiferimentoUtente: userData.RagioneSociale,
          CodicePosizioneUtente: cart.attivita.CodicePosizioneUtente,
          Stato: STATO_ORDINE_NONPROCESSATO,
          Dimensione,
          ID_Menu: cart.attivita.Menu.id,
          StatoPagamento: false,
          TotaleProdotti: cart.totali.TotaleProdotti,
          TotaleOrdine: cart.totali.TotaleOrdine,
          CostoConsegnaDelivery: cart.totali.CostoDiConsegna,
          ElencoProdotti: [...cart.elencoProdotti.map((prod) => ({
            IdMenu: cart.attivita.Menu.id,
            IdCategoriaMenu: prod.IdCategoriaProdotto,
            IdProdottoMenu: prod.IdProdotto,
            Nome: prod.Nome,
            Prezzo: prod.Prezzo,
            Quantita: prod.Quantita,
            PrezzoTotale: prod.PrezzoTotale
          } as ProdottoOrdine))],
        } as Partial<Ordine>)),
        map((ordine) => CartActions.ConfermaOrdineSuccess({ordine}))
      )
  );

  confermaOrdineSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.ConfermaOrdineSuccess),
        tap(({ordine}) => {
          this.router.navigateByUrl('checkout/riepilogo', {replaceUrl: false});
        })
      ), {dispatch: false}
  );

  inviaOrdineAlServer$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.InviaOrdineAlServer,
        ),
        withLatestFrom(
          this.store.select(selectCartState),
        ),
        filter(([, cart]) => !!cart.ordine.MetodoDiPagamento || !!cart.ordine.Indirizzo),
        debounceTime(300),
        map(([action, cart]) => ({
          ...cart.ordine,
          ...action.DatiAggiuntiviOrdine
        } as Ordine)),
        switchMap((data) => this.cartService.confermaOrdine(data).pipe(
          map((ordine: Ordine) => CartActions.InviaOrdineAlServerSuccess({ordine})),
          catchError((error) => of(CartActions.InviaOrdineAlServerError({error})))
        ))
      )
  );

  inviaOrdineAlServerSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.InviaOrdineAlServerSuccess),
        tap(({ordine}) => {

          if (ordine.id && ordine.StatoInt === STATOINT_ORDINE.STATO_ORDINE_INATTESA) {
            this.store.dispatch(CartActions.ResetCart());
            this.store.dispatch(OrdiniActions.List.LoadItems({loading: false}));
            this.notificationService.success('Ordine inviato correttamente!');
            this.router.navigateByUrl('/ordini/detail/' + ordine.id, {replaceUrl: false});
            // this.router.navigateByUrl('/checkout/completed', {replaceUrl: true});
            return;
          }

          if (ordine.id && ordine.StatoInt === STATOINT_ORDINE.STATO_ORDINE_NONPROCESSATO) {
            this.router.navigateByUrl('/checkout/payment/' + ordine.id, {replaceUrl: true});
            return;
          }

        })
      ), {dispatch: false}
  );

  inviaOrdineAlServerError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.InviaOrdineAlServerError),
        tap((action) => {
          console.log(action);
        })
      ), {dispatch: false}
  );

  loadOrdineDaPagare$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.LoadOrdineDaPagare,
        ),
        debounceTime(300),
        switchMap((action) => this.cartService.getById(action.id).pipe(
          map((ordine: Ordine) => CartActions.LoadOrdineDaPagareSuccess({ordineDaPagare: ordine})),
          catchError(() => of(CartActions.LoadOrdineDaPagareError()))
        ))
      )
  );

  sendPagamento$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.SendPagamento,
        ),
        debounceTime(500),
        switchMap((action) => this.cartService.inviaPagamento(action.idOrdine, {
          StatoPagamento: true,
          Stato: STATO_ORDINE_INATTESA,
          PaymentId: action.paymentId
        }).pipe(
          map((ordine: Ordine) => CartActions.SendPagamentoSuccess({ordine})),
          catchError(() => of(CartActions.SendPagamentoError()))
        ))
      )
  );

  sendPagamentoSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.SendPagamentoSuccess),
        tap(({ordine}) => {

          if (ordine.id && ordine.StatoInt === STATOINT_ORDINE.STATO_ORDINE_INATTESA) {
            this.store.dispatch(CartActions.ResetCart());
            // this.router.navigateByUrl('/checkout/completed', {replaceUrl: true});
            this.store.dispatch(OrdiniActions.List.LoadItems({loading: false}));
            this.notificationService.success('Pagamento processato correttamente!');
            this.router.navigateByUrl('/ordini/detail/' + ordine.id, {replaceUrl: false});
            return;
          }

        })
      ), {dispatch: false}
  );

  sendPagamentoError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.SendPagamentoError),
        tap((action) => {
          console.log(action);
        })
      ), {dispatch: false}
  );

  confirmResetCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.ConfirmResetCart),
        switchMap(() => this.alertController.create({
            header: 'Attenzione',
            message: `Sicuro di voler annullare questo ordine?`,
            buttons: [
              {
                text: 'Si', role: 'ok',
              },
              {
                text: 'No', role: 'cancel'
              }
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        ),
        filter((res) => res.role === 'ok'),
        map(() => {
          this.router.navigateByUrl('/home', {replaceUrl: true});
          return CartActions.ResetCart();
        })
      )
  );

  onSetAttivitaOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.SetAttivitaOrdine),
        withLatestFrom(
          this.store.select(ProfileSelectors.SelectIndirizzoProfiloSelezionato),
          this.store.select(CartSelectors.SelectIsPreordine)
        ),
        map(([{attivita}, IndirizzoUtente, isPreordine]) => {
          // array di actions da eseguire
          const actions = [
            CartActions.SetIndirizzoOrdine({IndirizzoUtente}),
          ];

          const DataConsegna = attivita.InfoTemporaliAttivita.Data.format(environment.defaultValueDataFormat);

          let setDatiConsegnaAction$;
          if (!isPreordine) {
            setDatiConsegnaAction$ = CartActions.SetOrarioRichiestoOrdineSuccess({
              OrarioRichiesto: null,
              ConsegnaPrimaPossibile: true,
              DataConsegna
            });
          } else {
            setDatiConsegnaAction$ = CartActions.SetConsegnaPrimaPossibileSuccess({
              OrarioRichiesto: null,
              ConsegnaPrimaPossibile: false,
              DataConsegna
            });
          }

          actions.push(setDatiConsegnaAction$);
          return actions;
        }),
        mergeMap((actions) => actions)
      )
  );

  openScegliMetodopagamentoActionSheet$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.OpenScegliMetodoDiPagamentoActionSheet),
        exhaustMap(() => this.actionSheetController.create({
          header: 'Scegli ...',
          buttons: [{
            text: 'Carta Di Credito',
            icon: 'card-outline',
            handler: () => this.store.dispatch(CartActions.SetMetodoDiPagamentoOrdine({MetodoDiPagamento: METODO_DI_PAGAMENTO_ORDINE_STRIPE}))
          }, {
            text: 'PayPal',
            icon: 'logo-paypal',
            handler: () => this.store.dispatch(CartActions.SetMetodoDiPagamentoOrdine({MetodoDiPagamento: METODO_DI_PAGAMENTO_ORDINE_PAYPAL}))
          }, {
            text: 'Contanti alla Consegna',
            icon: 'wallet-outline',
            handler: () => this.store.dispatch(CartActions.SetMetodoDiPagamentoOrdine({MetodoDiPagamento: METODO_DI_PAGAMENTO_ORDINE_CONTANTI}))
          }, {
            text: 'Annulla',
            role: 'cancel'
          }]
        })),
        map((actionSheet) => actionSheet.present())
      ), {dispatch: false}
  );

  openOrarioRichiestoOrdinePicker$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.OpenOrarioRichiestoPicker),
        withLatestFrom(
          this.store.select(CartSelectors.SelectOrariDiConsegnaPickerByFasciaAttiva)
        ),
        switchMap(([, pickerOptions]) => this.pickerController.create({
          buttons: [
            {
              text: 'Annulla',
              role: 'cancel'
            },
            {
              text: 'OK',
              role: 'ok'
            }
          ],
          columns: [
            {
              name: 'Orario',
              options: pickerOptions
            }
          ]
        }).then((picker) => {
          picker.present();
          return picker.onDidDismiss().then((res) => ({
            picker,
            res
          }));
        })),
        switchMap(async ({picker, res}) => ({
          role: res.role,
          col: await picker.getColumn('Orario')
        })),
        map(({col, role}) => {
          const selectedColumn = col.options[col.selectedIndex];

          // questa ciclo serve ad evitare che si sovrappongano i dati alla seconda chiamata del picker
          col.options.forEach(element => {
            delete element.selected;
            delete element.duration;
            delete element.transform;
          });

          if (selectedColumn && role === 'ok') {
            return CartActions.SetOrarioRichiestoOrdine({
              OrarioRichiesto: selectedColumn.value
            });
          } else {
            return DoNothingAction();
          }
        })
      )
  );

  setConsegnaPrimaPossibile$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.SetConsegnaPrimaPossibile),
        withLatestFrom(this.store.select(CartSelectors.SelectOrdine)),
        map(([{ConsegnaPrimaPossibile}, ordine]) => {

            const OrarioRichiesto = (ConsegnaPrimaPossibile) ? null : ordine.OrarioRichiesto;
            const DataConsegna = (ConsegnaPrimaPossibile) ? moment().format('YYYY-MM-DD') : ordine.DataConsegna;

            return CartActions.SetConsegnaPrimaPossibileSuccess({ConsegnaPrimaPossibile, OrarioRichiesto, DataConsegna});
          }
        ))
  );

  setOrarioRichiestoOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.SetOrarioRichiestoOrdine),
        map(({OrarioRichiesto}) => {

            const DataConsegna = moment(OrarioRichiesto, 'YYYY-MM-DD HH:mm').locale('it').format('YYYY-MM-DD');
            const ConsegnaPrimaPossibile = !OrarioRichiesto;
            OrarioRichiesto = moment(OrarioRichiesto, 'YYYY-MM-DD HH:mm').locale('it').format('HH:mm');

            return CartActions.SetOrarioRichiestoOrdineSuccess({OrarioRichiesto, ConsegnaPrimaPossibile, DataConsegna});
          }
        ))
  );

  resetCartIfEmpty$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartActions.DeleteProduct),
        withLatestFrom(this.store.select(CartSelectors.SelectTotProductsInCart)),
        map(([, totProductsInCart]) => (totProductsInCart <= 0) ? CartActions.ResetCart() : DoNothingAction())
      )
  );

  refreshTotaliCart$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.InsertProduct,
          CartActions.UpdateProduct,
          CartActions.DeleteProduct,
          CartActions.ResetCart,
          CartActions.SetMetodoDiPagamentoOrdine,
          CartActions.SetOrarioRichiestoOrdineSuccess,
          CartActions.SetConsegnaPrimaPossibileSuccess
        ),
        withLatestFrom(this.store.select(selectCartState)),
        map(([, cartState]) => {
          const TotaleProdotti = cartState.elencoProdotti.reduce((tot: number, prod: ProdottoCart) => (tot += prod.PrezzoTotale), 0);
          return {
            TotaleProdotti,
            CostoDiConsegna: cartState.attivita?.CostoDiConsegna,
            TotaleOrdine: (TotaleProdotti + cartState.attivita?.CostoDiConsegna)
          } as TotaliCarrello;
        }),
        map((totali) => CartActions.SetTotaliCart({totali}))
      )
  );

  persistData$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          CartActions.SetTotaliCart,
          CartActions.ConfermaOrdineSuccess,
          CartActions.InviaOrdineAlServerSuccess
        ),
        withLatestFrom(this.store.pipe(select(selectCartState))),
        tap(([, state]) =>
          this.localStorageService.setItem(CART_KEY, state)
        )
      ),
    {dispatch: false}
  );

  logoutAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.Logout),
        tap(() => this.localStorageService.removeItem(CART_KEY))
      ), {dispatch: false}
  );

}
