export const APPERROR_NO_USER_CONFIGS_DATA = 'APPERROR_NO_USER_CONFIGS_DATA';
export const APPERROR_APP_IN_MANUTENZIONE = 'APPERROR_APP_IN_MANUTENZIONE';

export const APPERROR_GENERIC_ERROR = 'APPERROR_GENERIC_ERROR';

export class AppError extends Error {
    public errorCode: string;
    public details: any;

    constructor(errorCode: string, message: string = null, details: any = null) {
        super();

        this.errorCode = errorCode;
        this.details = details;

        if (message) {
            this.message = message;
        }
    }
}
