import {Injectable} from '@angular/core';
import {Capacitor, HapticsImpactStyle, Plugins} from '@capacitor/core';

const {Haptics} = Plugins;

@Injectable({
  providedIn: 'root'
})
export class HapticsService {

  constructor() {
  }

  hapticPress() {
    if (Capacitor.platform !== 'web') {
      Haptics.impact({style: HapticsImpactStyle.Medium});
    }
  }

  hapticPressLight() {
    if (Capacitor.platform !== 'web') {
      Haptics.impact({style: HapticsImpactStyle.Light});
    }
  }

  hapticPressHeavy() {
    if (Capacitor.platform !== 'web') {
      Haptics.impact({style: HapticsImpactStyle.Heavy});
    }
  }

}
