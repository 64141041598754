import {Component} from '@angular/core';
import {Capacitor, Plugins, StatusBarStyle} from '@capacitor/core';
import {environment as ENV} from '../../environments/environment';
import {Platform} from '@ionic/angular';

const {StatusBar} = Plugins;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
  ) {
    this.initializeApp();
  }

  initializeApp() {

    this.platform.ready().then(() => {

      if (Capacitor.platform === 'android') {
        StatusBar.setBackgroundColor({color: ENV.backgroundPrimaryColor});
      }

      // Disattivato perchè la barra è sempre chiara di base
      /*if (Capacitor.platform === 'ios') {
        StatusBar.setStyle({style: StatusBarStyle.Dark});
      }*/

      // this.store.dispatch(AppConfigsActions.CheckTokenNotifiche());
    });

  }
}
