import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {AlertController} from '@ionic/angular';

import {of} from 'rxjs';
import {concatMap, filter, map, switchMap, tap, withLatestFrom} from 'rxjs/operators';

import {AppState} from '../../../core.state';
import {CartActions, CartProductActions} from '../actions';
import {CartSelectors} from '../selectors';

import {LocalStorageService} from '../../../local-storage/local-storage.service';

@Injectable()
export class CartProductsEffects {

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private store: Store<AppState>,
    private alertController: AlertController
  ) {
  }

  handleSetProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.HandleSetProduct),
        // controllo Delivery ON
        map(({prodotto, attivita}) => {
          // controllo se l'attivita che voglio inserire è con il delivery attivo
          if (!attivita.InfoTemporaliAttivita.DeliveryAttivo) {
            return CartProductActions.AvvisoDeliveryOff(); // blocco se non ha passato il controllo di delivery on
          } else {
            return CartProductActions.ControllaCambioOrdineAttivita({prodotto, attivita});
          }
        })
      )
  );

  avvisoDeliveryOff$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.AvvisoDeliveryOff),
        switchMap(() => this.alertController.create({
          header: 'Attività Chiusa',
          subHeader: 'Al momento questo locale non accetta ordini. Riprova più tardi!',
          message: '',
          buttons: ['OK']
        }).then((alert) => {
          alert.present();
        }))
      ), {dispatch: false}
  );

  // Il Delivery è ON ora Controllo se sono in preordine avviso pre ordine
  controllaPreOrdineAttivita$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.ControllaPreOrdineAttivita),
        withLatestFrom(
          this.store.select(CartSelectors.SelectAttivitaOrdine),
          this.store.select(CartSelectors.SelectIsPreordine)
        ),
        map(([{prodotto, attivita}, attivitaInCart, isPreordine]) => {
          if (!attivita.InfoTemporaliAttivita.AttivaOra && attivita.InfoTemporaliAttivita.PreOrdinePossibile) {
            if (!attivitaInCart || (!!attivitaInCart && attivitaInCart.id !== attivita.id)) { // chiedo solo se non è già l'attivita presente nel carrello
              return CartProductActions.AvvisoDeliveryPreOrdine({prodotto, attivita}); // vado all'avviso per il preordine
            }
          }
          // Continuo con il controllo cambio attività ordine
          return CartProductActions.OnSetProduct({prodotto, attivita, isPreordine});
        }),
      )
  );

  avvisoDeliveryPreOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.AvvisoDeliveryPreOrdine),
        switchMap(({prodotto, attivita}) => this.alertController.create({
          header: `La prima consegna diponibile è per ${attivita.InfoTemporaliAttivita.ArcoTemporaleFormatted}`,
          subHeader: 'Continua per effetuare un pre ordine',
          message: '',
          buttons: [
            {
              role: 'cancel',
              text: 'Annulla'
            },
            {
              role: 'ok',
              text: 'Pre ordina',
              handler: () => ({prodotto, attivita})
            }]
        }).then((alert) => {
          alert.present();
          return alert.onDidDismiss();
        })),
        filter((alert) => alert.role !== 'cancel' && alert.data.hasOwnProperty('attivita')),
        map(({role, data}) => ({
          prodotto: data.prodotto,
          attivita: data.attivita
        })),
        map(({prodotto, attivita}) => CartProductActions.OnSetProduct({prodotto, attivita, isPreordine: true}))
      )
  );

  controllaCambioOrdineAttivita$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.ControllaCambioOrdineAttivita),
        withLatestFrom(this.store.select(CartSelectors.SelectAttivitaOrdine)),
        map(([{prodotto, attivita}, attivitaInCart]) => {
          if (!!attivitaInCart && attivitaInCart.id !== attivita.id) { // attivita presente nel carrello e attiva da inserire diversa da quella già presente
            // alert che fa scegliere come proseguire
            return CartProductActions.AvvisoCambioAttivitaOrdine({prodotto, attivita});
          } else {
            // return CartProductActions.OnSetProduct({prodotto, attivita}); // prima andava qui diretto
            return CartProductActions.ControllaPreOrdineAttivita({prodotto, attivita});
          }
        })
      )
  );

  avvisoCambioAttivitaOrdine$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.AvvisoCambioAttivitaOrdine),
        withLatestFrom(this.store.select(CartSelectors.SelectAttivitaOrdine)),
        switchMap(([{prodotto, attivita}, attivitaInCart]) => this.alertController.create({
            header: `Iniziare un nuovo ordine?`,
            subHeader: `Hai già un ordine in corso per il locale ${attivitaInCart.RagioneSociale}.`,
            message: `Vuoi cancellare l'ordine in corso e iniziare un nuovo ordine per il locale <b>${attivita.RagioneSociale}</b>?`,
            buttons: [
              {
                text: 'NO', role: 'cancel',
              },
              {
                text: 'SI', role: 'ok',
                handler: () => {
                  return {prodotto, attivita};
                }
              },
            ]
          }).then((alert) => {
            alert.present();
            return alert.onWillDismiss();
          })
        ),
        filter((res) => res.role === 'ok'),
        map((res) => ({
          prodotto: res.data.prodotto,
          attivita: res.data.attivita
        })),
        concatMap(({prodotto, attivita}) => [
          CartActions.ResetCart(),
          CartProductActions.ControllaPreOrdineAttivita({prodotto, attivita})
        ])
      )
  );

  onSetProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.OnSetProduct),
        withLatestFrom(this.store.select(CartSelectors.SelectAttivitaOrdine)),
        concatMap(([{prodotto, attivita, isPreordine}, attivitaInCart]) => {
          // Attivita nel carrello diversa da quella che voglio inserire quindi la reimposto
          if (attivitaInCart?.id !== attivita.id) {
            return [
              CartActions.SetAttivitaOrdine({attivita}),
              CartProductActions.SetIsPreordine({isPreordine}),
              CartProductActions.SetProduct({prodotto})
            ];
          } else {
            // Nessuna attivita nel carrello procedo all'inserimento
            return [
              CartProductActions.SetIsPreordine({isPreordine}),
              CartProductActions.SetProduct({prodotto})
            ];
          }
        })
      )
  );

  setProduct$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(CartProductActions.SetProduct),
        withLatestFrom(
          this.store.select(CartSelectors.SelectProdottiOrdine)
        ),
        map(([{prodotto}, prodottiCarrello]) => ({
          prodotto,
          prodottoCarrello: prodottiCarrello.find((p) => p.IdProdotto === prodotto.IdProdotto)
        })),
        switchMap(({prodotto, prodottoCarrello}) => {

            const Quantita = (!prodotto.Quantita) ? 0 : Number(prodotto.Quantita);
            const PrezzoTotale = prodotto.Quantita * prodotto.Prezzo;

            if (Quantita <= 0) { // Elimino il prodotto
              // Rimuovo dal carrello
              return of(CartActions.DeleteProduct({id: prodotto.id}));
            } else if (!!prodottoCarrello) { // Aggiorno il prodotto
              const prod = {
                ...prodotto,
                Quantita,
                PrezzoTotale
              };
              return of(CartActions.UpdateProduct({prodotto: prod}));
            } else if (!prodottoCarrello) { // Inserisco come nuovo prodotto
              const prod = {
                ...prodotto,
                Quantita,
                PrezzoTotale
              };
              return of(CartActions.InsertProduct({prodotto: prod}));
            }
          }
        )
      )
  );

}
