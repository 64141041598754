import {Injectable} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';

import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Store} from '@ngrx/store';

import {environment as ENV} from '../../../environments/environment';
import {NotificationService} from '../../shared/services/notification.service';
import {AppState} from '../core.state';
import {AuthActions} from '../auth/store/actions';
import {Router} from '@angular/router';
import {Capacitor} from '@capacitor/core';

export interface V1ApiError {
  status: boolean;
  message: string;
  errorCode: string;
  exception_trace: any;
  request: any;
}

const WEBSERVICE_SESSION_EXPIRED = 'WEBSERVICE_SESSION_EXPIRED';
const WEBSERVICE_GENERIC_ERROR = 'WEBSERVICE_GENERIC_ERROR';

/** Passes HttpErrorResponse to application-wide error handler */
@Injectable()
export class V1HttpErrorInterceptor implements HttpInterceptor {

  // l'interceptor funziona solo per il valore apiUrl principale
  urlCheckRegex = new RegExp(`${ENV.apiUrl.replace('https://', '')}/${ENV.apiVersion}`);

  constructor(private notificationService: NotificationService,
              private router: Router,
              private store: Store<AppState>) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap({
        error: (err: any) => {
          if (err instanceof HttpErrorResponse && this.urlCheckRegex.test(request.url)) {

            if (err.error && err.error.errorCode) {
              this.WebServiceErrorHandler(err.error);
            } else {
              return;
            }
          }
        }
      })
    );
  }

  /**
   * Funzione per la gestione degli errori che vengono restituiti dal webservice
   */
  private WebServiceErrorHandler(err: V1ApiError) {

    switch (err.errorCode) {
      case WEBSERVICE_SESSION_EXPIRED:
        // this.notificationService.danger(err.message, 3000);
        this.store.dispatch(AuthActions.Logout());
        break;
      case WEBSERVICE_GENERIC_ERROR:
      default:
        this.notificationService.danger(err.message, 3000);
        if (!ENV.production) {
          console.log('Errore', err);
        }
        break;
    }
  }
}
