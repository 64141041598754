import {createAction, props} from '@ngrx/store';
import {Ordine} from '../../../../models/ordini.model';
import {IonRefresher} from '@ionic/angular';

export const LoadItems = createAction(
    '[Ordini][List] Load Items',
    props<{ event?: any, loading: boolean, page?: number, count?: number, order?: string }>()
);

export const LoadItemsSuccess = createAction(
    '[Ordini][List] Load Items Success',
    props<{ items: Ordine[], totItems: number, page: number }>()
);

export const LoadItemsError = createAction(
    '[Ordini][Init] Load Items Error',
    props<{ error: any }>()
);

export const StartLoader = createAction(
    '[Ordini][List] Start Loader',
);

export const OpenOrdiniFiltersModal = createAction(
    '[Ordini][List] Open Ordini Filters Modal',
);

export const ResetListState = createAction(
    '[Ordini][List] Reset',
);

export const SetFilters = createAction(
    '[Ordini][List] Set filters',
    props<{ filters: any }>()
);

export const SetPage = createAction(
    '[Ordini][List] Set Page',
    props<{ page: number }>()
);

export const RemoveFilter = createAction(
    '[Ordini][List] Remove filters',
    props<{ filter: any }>()
);

export const ResetFilters = createAction(
    '[Ordini][List] Reset filters',
    props<{ TipoOrdini: string }>()
);
