import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {
    Plugins,
    PushNotification,
    PushNotificationToken,
    PushNotificationActionPerformed,
    Capacitor
} from '@capacitor/core';
import {HttpClient} from '@angular/common/http';
import {Store} from '@ngrx/store';

import {MyApiProvider} from '../../shared/abstract/my.api';
import {AppState} from '../core.state';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {AuthActions} from '../auth/store/actions';
import {NotificationService} from '../../shared/services/notification.service';

const { PushNotifications } = Plugins;

@Injectable({
    providedIn: 'root'
})
export class FcmService extends MyApiProvider {

    constructor(public http: HttpClient,
                private router: Router,
                private notification: NotificationService,
                public store: Store<AppState>) {
        super(http, '/notify');
    }

    initPush() {
        if (Capacitor.platform !== 'web') {
            this.registerPush();
        }
    }

    registerTokenToServer(token: string){
        return this.post({Token: token}).pipe(
            map((res: any) => res),
            catchError(error => throwError(error))
        );
    }

    private registerPush() {

        PushNotifications.requestPermission().then((permission) => {
            if (permission.granted) {
                // Register with Apple / Google to receive push via APNS/FCM
                PushNotifications.register();
            } else {
                // No permission for push granted
            }
        });

        PushNotifications.addListener(
            'registration',
            (token: PushNotificationToken) => {
                this.store.dispatch(AuthActions.UpdateTokenNotifichePush({token: token.value}));
            }
        );

        PushNotifications.addListener('registrationError', (error: any) => {
            console.log('Error: ' + JSON.stringify(error));
        });

        // Ricezione della notifica mentre l'app è in esecuzione
        PushNotifications.addListener(
            'pushNotificationReceived',
            async (notification: PushNotification) => {
                const data = notification.data;
                /*switch(data.action){
                    case 'ORDER_STATUS_CHANGE':
                        if (this.router.url.match(/home/)) {
                            // console.log('url match home');
                            this.store.dispatch(HomeActions.LoadItems({loading: true, periodo: 'last-seven'}));
                        }else{
                            // console.log('no match url home');
                            this.store.dispatch(OrdiniActions.List.LoadItems({loading: true}));
                        }
                        this.notification.default(notification.body);
                        break;
                }*/
            }
        );

        // Apertura della notifica in background
        PushNotifications.addListener(
            'pushNotificationActionPerformed',
            async (notification: PushNotificationActionPerformed) => {
                console.log('New pushNotificationActionPerformed');

                const data = notification.notification.data;

                if(data.url){
                    this.router.navigateByUrl(data.url);
                    return;
                }

                /*switch(data.action){
                    case 'ORDER_STATUS_CHANGE':
                        this.store.dispatch(HomeActions.LoadItems({loading: true, periodo: 'last-seven'}));
                        break;
                }*/

                PushNotifications.removeAllDeliveredNotifications().then(() => null);
            }
        );

    }

}
