import {createReducer, on, Action} from '@ngrx/store';
import {AuthActions} from '../../../auth/store/actions';
import {HomeActions} from '../../../../features/tab-home/store/actions';
import {ProfileActions} from '../actions';

export interface ProfileState {
  loading: boolean;
  currentGeolocation: UserGeolocation;
  indirizzoSelezionato: IndirizzoUtente;
  metodiDiPagamento: any[];
  indirizzi: any[];
}

export interface UserGeolocation {
  lat: number;
  lng: number;
}

export interface IndirizzoUtente {
  formattedString: string;
  lat: number;
  lng: number;
}

/*
 INITIAL STATES
 */

export const initialLocation: UserGeolocation = {
  lat: null,
  lng: null
};

export const initialIndirizzoSelezionato: IndirizzoUtente = {
  formattedString: '',
  lat: null,
  lng: null
};

export const initialState: ProfileState = {
  loading: false,
  currentGeolocation: initialLocation,
  indirizzoSelezionato: initialIndirizzoSelezionato,
  metodiDiPagamento: [],
  indirizzi: []
};

const reducer = createReducer(
  initialState,

  on(ProfileActions.SetCurrentGeolocation, (state, {currentGeolocation}) => ({
    ...state,
    currentGeolocation
  })),
  on(ProfileActions.SetIndirizzoSelezionato, (state, {indirizzoUtente}) => ({
    ...state,
    indirizzoSelezionato: indirizzoUtente
  })),
  on(ProfileActions.ResetIndrizzoUtente, (state) => ({
    ...state,
    indirizzoSelezionato: initialState.indirizzoSelezionato
  })),

  on(AuthActions.Logout, state => ({
    ...state,
    ...initialState
  }))
);

export function profileReducer(
  state: ProfileState | undefined,
  action: Action
): ProfileState {
  return reducer(state, action);
}
