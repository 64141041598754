import {ErrorHandler, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DefaultRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule} from '@ngrx/router-store';

import {environment as ENV} from '../../environments/environment';

import {AppErrorHandler} from './error-handler/app-error-handler.service';
import {V1HttpErrorInterceptor} from './http-interceptors/v1-http-error-interceptor.service';
import {AuthInterceptor} from './auth/interceptors/auth.interceptors';
import {CustomSerializer} from './router/custom-serializer';

import {metaReducers, reducers} from './core.state';

import {AuthEffects} from './auth/store/effects/auth.effects';
import {ProfileEffects} from './profile/store/effects/profile.effects';
import {ConfigsEffects} from './configs/store/effects/configs.effects';
import {cartEffects} from './cart/store/effects';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    `assets/i18n/`,
    '.json'
  );
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,

    StoreModule.forRoot(reducers, {metaReducers}),
    StoreRouterConnectingModule.forRoot({serializer: DefaultRouterStateSerializer}),
    EffectsModule.forRoot([
      AuthEffects,
      ...cartEffects,
      ProfileEffects,
      ConfigsEffects
    ]),

    ENV.production ? [] : StoreDevtoolsModule.instrument({name: 'MoveDelivery', maxAge: 30}),

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: V1HttpErrorInterceptor, multi: true},
    {provide: ErrorHandler, useClass: AppErrorHandler},
    {provide: RouterStateSerializer, useClass: CustomSerializer}
  ]
})

export class CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
      parentModule: CoreModule
  ) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
