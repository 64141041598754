// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appName: 'Scarpetta',
  envName: 'DEV',
  production: false,
  appVersion: '0.0.1',
  authUrl: 'https://apidev.movedelivery.it/v1/login',
  apiUrl: 'https://apidev.movedelivery.it',
  socketUrl: 'https://wsdev.movedelivery.it',
  // socketUrl: 'http://localhost:3000',
  apiVersion: 'v1',
  localStoragePrefix: 'MVD-',
  defaultValueDataFormat: 'YYYY-MM-DD',
  defaultStringDataFormat: 'DD/MM/YYYY',
  i18nPrefix: 'MVDI',
  defaultCountList: 2,
  logoImageBaseUrl: 'https://apidev.movedelivery.it/imgrepo/attivita/logo/',
  bannerImageBaseUrl: 'https://apidev.movedelivery.it/imgrepo/attivita/banner/',
  backgroundPrimaryColor: '#BA4F98',
  stripe_public_key: 'pk_test_b6dH9bnA5HZblTpMf8dszYq500qWr1kbGj',

  payPalEnv: 'PayPalEnvironmentSandbox',
  payPalProductionId: '',
  payPalSandboxId: 'AccVkGVhW30ZYNolZG-QhgYhIGjBkrM_auGMj8xl0a8sYhEO2AltnazQeRmoBvMUPFqv3mDecpk9k93o',
  payPalShopName: 'agenobil-facilitator@gmail.com',
  payPalMerchantPrivacyPolicyURL: 'https://www.movecitycourier.com/cookie-policy',
  payPalMerchantUserAgreementURL: 'https://www.movecitycourier.com/cookie-policy',
  paypalCurrency: 'EUR'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
