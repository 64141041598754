import {createReducer, on, Action} from '@ngrx/store';
import {Attivita} from '../../../../models/attivita.model';
import {AuthActions} from '../../../auth/store/actions';
import {ProdottoCart} from '../../../../models/cart.model';
import {CartActions, CartProductActions} from '../actions';
import {Ordine} from '../../../../models/ordini.model';

export interface CartState {
  loading: boolean;
  attivita: Attivita;
  ordine: Partial<Ordine>;
  elencoProdotti: ProdottoCart[];
  isPreordine: boolean;
  totali: TotaliCarrello;
  ordineDaPagare: Ordine;
}

export interface TotaliCarrello {
  TotaleProdotti: number;
  CostoDiConsegna: number;
  TotaleOrdine: number;
}

/*
 INITIAL STATES
 */

export const initialState: CartState = {
  loading: false,
  attivita: null,
  ordine: {ConsegnaPrimaPossibile: true},
  elencoProdotti: [],
  isPreordine: false,
  totali: null,
  ordineDaPagare: null
};

const reducer = createReducer(
  initialState,

  on(CartActions.SetAttivitaOrdine, (state, {attivita}) => ({
    ...state,
    attivita
  })),
  on(CartActions.ResetCart, (state) => ({
    ...initialState
  })),
  on(CartActions.SetLoading, (state, {loading}) => ({
    ...state,
    loading
  })),
  on(CartProductActions.SetIsPreordine, (state, {isPreordine}) => ({
    ...state,
    isPreordine
  })),
  on(CartActions.SetTotaliCart, (state, {totali}) => ({
    ...state,
    totali
  })),

  on(CartActions.InsertProduct, (state, {prodotto}) => ({
    ...state,
    elencoProdotti: [...state.elencoProdotti, prodotto]
  })),
  on(CartActions.UpdateProduct, (state, {prodotto}) => ({
    ...state,
    elencoProdotti: state.elencoProdotti.map((prod) => (prod.id !== prodotto.id) ? prod : {
      ...prod,
      ...prodotto
    })
  })),
  on(CartActions.DeleteProduct, (state, {id}) => ({
    ...state,
    elencoProdotti: state.elencoProdotti.filter((prod) => (prod.id !== id))
  })),

  on(CartActions.SetMetodoDiPagamentoOrdine, (state, {MetodoDiPagamento}) => ({
    ...state,
    ordine: {
      ...state.ordine,
      MetodoDiPagamento
    }
  })),
  on(CartActions.SetOrarioRichiestoOrdineSuccess, (state, {OrarioRichiesto, ConsegnaPrimaPossibile, DataConsegna}) => ({
    ...state,
    ordine: {
      ...state.ordine,
      ConsegnaPrimaPossibile,
      OrarioRichiesto,
      DataConsegna
    }
  })),
  on(CartActions.SetConsegnaPrimaPossibileSuccess, (state, {ConsegnaPrimaPossibile, OrarioRichiesto, DataConsegna}) => ({
    ...state,
    ordine: {
      ...state.ordine,
      ConsegnaPrimaPossibile,
      OrarioRichiesto,
      DataConsegna
    }
  })),
  on(CartActions.SetIndirizzoOrdine, (state, {IndirizzoUtente}) => ({
    ...state,
    ordine: {
      ...state.ordine,
      Indirizzo: IndirizzoUtente.formattedString,
      Latitudine: IndirizzoUtente.lat,
      Longitudine: IndirizzoUtente.lng
    }
  })),

  on(CartActions.ConfermaOrdine, (state) => ({
    ...state,
    loading: true
  })),
  on(CartActions.ConfermaOrdineSuccess, (state, {ordine}) => ({
    ...state,
    loading: false,
    ordine: {...state.ordine, ...ordine},
  })),

  on(CartActions.InviaOrdineAlServer, (state) => ({
    ...state,
    loading: true
  })),
  on(CartActions.InviaOrdineAlServerSuccess, (state) => ({
    ...state,
    ...initialState
  })),
  on(CartActions.InviaOrdineAlServerError, (state) => ({
    ...state,
    loading: false
  })),

  on(CartActions.LoadOrdineDaPagare, (state) => ({
    ...state,
    loading: true
  })),
  on(CartActions.LoadOrdineDaPagareSuccess, (state, {ordineDaPagare}) => ({
    ...state,
    loading: false,
    ordineDaPagare
  })),
  on(CartActions.LoadOrdineDaPagareError, (state) => ({
    ...state,
    loading: false,
    ordineDaPagare: null
  })),

  on(CartActions.SendPagamento, (state) => ({
    ...state,
    loading: true
  })),
  on(CartActions.SendPagamentoSuccess, (state, {ordine}) => ({
    ...state,
    loading: false,
    ordine
  })),
  on(CartActions.SendPagamentoError, (state) => ({
    ...state,
    loading: false
  })),

  on(AuthActions.Logout, state => ({
    ...state,
    ...initialState
  }))
);

export function cartReducer(
  state: CartState | undefined,
  action: Action
): CartState {
  return reducer(state, action);
}
