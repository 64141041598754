import {Injectable} from '@angular/core';
import {MyApiProvider} from '../../../shared/abstract/my.api';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class AreeconsegnaService extends MyApiProvider {

    constructor(public http: HttpClient) {
        super(http, '/areeconsegna');
    }

}
