import {HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';

import {environment as ENV} from '../../../../environments/environment';

import {Store} from '@ngrx/store';
import {first, flatMap} from 'rxjs/operators';
import {AppState} from '../../core.state';
import {AuthSelectors} from '../store/selectors';

const PUBLIC_RESOURCES = ['attivita', 'tipologieattivita', 'menu'];

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  /*baseHeaders: any = (ENV.production) ? {
    'Content-Type': 'application/json; charset=utf-8'
  } : {
    'Content-Type': 'application/json; charset=utf-8',
    'X-As-Dev-Pw': ENV.devModeHeaderPw,
    'X-As-Dev-On': '1',
  };*/

  baseHeaders: any = {
    'Content-Type': 'application/json; charset=utf-8'
  };

  // l'interceptor funziona solo per il valore apiUrl principale
  urlCheckRegex = new RegExp(`${ENV.apiUrl.replace('https://', '')}/${ENV.apiVersion}`);
  resourceUrlCheckRegex = new RegExp(`${PUBLIC_RESOURCES.join('|')}`);

  constructor(
    private store: Store<AppState>) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return this.store.select(AuthSelectors.SelectToken).pipe(
      first(),
      flatMap(token => {
        const headers = (!!token && this.urlCheckRegex.test(req.url) && !this.resourceUrlCheckRegex.test(req.url)) ? {
          ...this.baseHeaders,
          Authorization: `Bearer ${token}`
        } : this.baseHeaders;
        return next.handle(req.clone({
          setHeaders: headers,
        }));
      }),
    );
  }

}
