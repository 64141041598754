import {Moment} from 'moment';

export interface FasciaOraria {
    id: string;
    Lunedi: number;
    Martedi: number;
    Mercoledi: number;
    Giovedi: number;
    Venerdi: number;
    Sabato: number;
    Domenica: number;
    Orario: string;
    Tipologia: string;
    AttivaOra?: boolean;
    DataControllata?: Moment;
}

export interface InfoTemporaliAttivita {
  Data: Moment;
  Giorno: string;
  ArcoTemporale: string;
  ArcoTemporaleFormatted: string;
  AttivaOra: boolean;
  DeliveryAttivo: boolean;
  PreOrdinePossibile: boolean;
}

export const EMPTY_INFO_TEMPORALI_ATTVITA: InfoTemporaliAttivita = {
  Data: null,
  DeliveryAttivo: false,
  ArcoTemporaleFormatted: '',
  PreOrdinePossibile: false,
  ArcoTemporale: '',
  AttivaOra: false,
  Giorno: null
};
