import {InfoTemporaliAttivita, FasciaOraria, EMPTY_INFO_TEMPORALI_ATTVITA} from '../../models/fascieorarie.model';
import * as moment from 'moment';
import {Moment} from 'moment';
import {Attivita} from '../../models/attivita.model';

export class FasceOrarieUtility {

  constructor() {
  }

  /**
   * Restituisce la prima fascia oraria disponibile a prenotare e indica se e attiva all'orario attuale o no
   * @param attivita
   * @param dataStart
   */
  static getInfoTemporaliAttivita(attivita: Attivita, dataStart?: Moment): InfoTemporaliAttivita {

    if (!attivita?.FasceOrarie || !attivita.stato) {
      return EMPTY_INFO_TEMPORALI_ATTVITA;
    }

    dataStart = dataStart ?? moment().locale('it'); // Data passata con orario attuale
    const dataEnd = dataStart.clone().add(7, 'days');

    let fasciaTrovata: FasciaOraria = null;
    while (dataStart.isSameOrBefore(dataEnd, 'day') && !fasciaTrovata) {  // cerco per i giorni a partire da oggi a 7 giorni

      let isAttivoOra = false;

      if (dataStart.isSame(moment().locale('it'), 'day')) {  // Cerco la fascia oraria attiva solo se la data è uguale a quella di oggi
        fasciaTrovata = this.getFasciaByGiorno(attivita.FasceOrarie, dataStart);
      }

      if (!fasciaTrovata) { // Se non la trovo cerco la prima fascia disponibile per il giorno
        const giorno = this.getGiornoData(dataStart);
        fasciaTrovata = attivita.FasceOrarie.filter((f) => f[giorno]).find((fascia) => {
          const f = fascia.Orario.split('-');
          const oraStart = moment(dataStart.clone().format('YYYY-MM-DD') + ' ' + f[0], 'YYYY-MM-DD HH:mm').locale('it');

          // se il giorno è oggi allora devo anche controllare che la fascia sia successiva all'orario attuale altrimenti posso resituire la prima che trovo
          if (dataStart.isSame(moment().locale('it'), 'day')) {
            return (oraStart.isAfter(dataStart.clone(), 'hour'));
          } else {
            return true;
          }
        });
      } else {
        isAttivoOra = true;
      }

      if (fasciaTrovata) {
        fasciaTrovata = {...fasciaTrovata, AttivaOra: isAttivoOra, DataControllata: dataStart.clone()};
      }

      dataStart.add(1, 'days');
    }

    return this.formatToInfoTemporaliAttivita(fasciaTrovata, attivita.DeliveryOn, attivita.PreOrdineOn);
  }

  static getArcoTemporaleFormatted(infoTemporali: InfoTemporaliAttivita, DeliveryOn: boolean) {

    // console.log(infoTemporali, DeliveryOn);

    const stringaChiusura = 'Ora Chiuso';

    if (!DeliveryOn) { // blocca a prescindere tutte le prenotazioni
      return stringaChiusura;
    }

    if (infoTemporali.AttivaOra) {
      return infoTemporali.ArcoTemporale.replace('-', ' - ');
    }

    if (!infoTemporali.AttivaOra && infoTemporali.PreOrdinePossibile) {
      const giorno = (this.getGiornoData(infoTemporali.Data) === this.getGiornoData(moment())) ? '' : 'Domani';
      return `${giorno} ${infoTemporali.ArcoTemporale.replace('-', ' - ')}`;
    }

    return stringaChiusura;
  }

  /**
   * Restituisce se esite la fascia oraria per la data cercata
   * @param fasceOrarie
   * @param data
   */
  static getFasciaByGiorno(fasceOrarie: FasciaOraria[], data?: Moment): FasciaOraria | null {

    data = data ?? moment().locale('it');
    const giorno = this.getGiornoData(data);

    let fasciaAttuale = null;
    fasceOrarie.filter((fascia) => fascia[giorno]).forEach((fascia) => {
      const f = fascia.Orario.split('-');
      const oraStart = moment(data.format('YYYY-MM-DD') + ' ' + f[0], 'YYYY-MM-DD HH:mm');
      const oraEnd = moment(data.format('YYYY-MM-DD') + ' ' + f[1], 'YYYY-MM-DD HH:mm');
      if (data.isBetween(oraStart, oraEnd, 'minute', '[]')) {
        fasciaAttuale = fascia;
      }
    });

    return fasciaAttuale;
  }

  static getGiornoData(data: Moment): string {
    const giorno = data.clone().locale('it').format('dddd').replace('ì', 'i');
    return giorno.charAt(0).toUpperCase() + giorno.slice(1);
  }

  static formatToInfoTemporaliAttivita(f: FasciaOraria, DeliveryOn: boolean, PreOrdineOn: boolean): InfoTemporaliAttivita {

    const tmp = {
      Data: f.DataControllata,
      Giorno: this.getGiornoData(f.DataControllata),
      ArcoTemporale: f.Orario,
      AttivaOra: f.AttivaOra ?? false,
      // è preordinabile solo se non è attivo ora e la data ottenuta è entro domani e se il check dal server DeliveryOn è true
      PreOrdinePossibile: (!f.AttivaOra && PreOrdineOn && f.DataControllata.isSameOrBefore(moment().add(1, 'day'), 'day'))
    } as InfoTemporaliAttivita;

    return {
      ...tmp,
      DeliveryAttivo: (tmp.AttivaOra || (!tmp.AttivaOra && tmp.PreOrdinePossibile)),
      ArcoTemporaleFormatted: this.getArcoTemporaleFormatted(tmp, DeliveryOn)
    } as InfoTemporaliAttivita;
  }

  /**
   * Ritorna se esiste la fascia oraria attuale del locale
   * @param fasceOrarie
   */
  static getFasciaAttuale(fasceOrarie: FasciaOraria[]): FasciaOraria | null {
    return this.getFasciaByGiorno(fasceOrarie);
  }

}
