import {createAction, props} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';
import {LoginFormData, UserAuthData} from '../reducers/auth.reducer';

export const InitCheckToken = createAction('[Auth] Check Token');

export const InitCheckTokenSuccess = createAction('[Auth] Check Token Success');

export const InitCheckTokenNull = createAction('[Auth] Check Token Null');

export const ControlloVersioneApp = createAction(
    '[Auth] Controllo Versione App');

export const ControlloVersioneAppSuccess = createAction(
    '[Auth] Controllo Versione App success');

export const ControlloVersioneAppNoChange = createAction(
    '[Auth] Controllo Versione App NoChange');

// Set Prev Url
export const SetPrevUrl = createAction(
  '[Auth] Set Prev url',
  props<{ url: string }>());

// Login Action

export const Login = createAction(
    '[Auth] Login',
    props<{ formDataLogin: LoginFormData }>());

export const LoginSuccess = createAction(
    '[Auth] Login Success',
    props<{ userAuthData: UserAuthData}>()
);

export const LoginError = createAction(
    '[Auth] Login Error',
    props<{ error: HttpErrorResponse }>()
);

export const SignIn = createAction(
  '[Auth] SignIn',
  props<{ userData: any }>());

export const SignInSuccess = createAction(
  '[Auth] SignIn Success',
  props<{ userData: any}>()
);

export const SignInError = createAction(
  '[Auth] SignIn Error',
  props<{ error: HttpErrorResponse }>()
);

export const MemorizzaCredenzialiLogin = createAction(
    '[Auth] Memorizza Credenziali LoginForm',
    props<{ formDataLogin: LoginFormData }>());

export const AlertLogout = createAction(
    '[Auth] Alert Logout');

export const Logout = createAction(
    '[Auth] Logout');

// Update Token Notifiche push
export const UpdateTokenNotifichePush = createAction(
    '[Auth] Update Token Notifiche Push',
    props<{ token: string }>());

export const UpdateTokenNotifichePushSuccess = createAction(
    '[Auth] Update Token Notifiche Push Success'
);

export const UpdateTokenNotifichePushError = createAction(
    '[Auth] Update Token Notifiche Push Error'
);



