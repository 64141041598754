export const ORDINI_IN_CORSO = 'ORDINI_IN_CORSO';
export const ORDINI_STORICO = 'ORDINI_STORICO';

export const TIPO_ORDINE_DELIVERY = 'DELIVERY';
export const TIPO_ORDINE_BUSINESS = 'BUSINESS';
export type TIPO_ORDINE_DELIVERY = 'DELIVERY';
export type TIPO_ORDINE_BUSINESS = 'BUSINESS';
export type TIPO_ORDINE = TIPO_ORDINE_DELIVERY | TIPO_ORDINE_BUSINESS;

export const DIMENSIONE_ORDINE_PICCOLA = 'PICCOLA';
export const DIMENSIONE_ORDINE_MEDIA = 'MEDIA';
export const DIMENSIONE_ORDINE_GRANDE = 'GRANDE';
export type DIMENSIONE_ORDINE_PICCOLA = 'PICCOLA';
export type DIMENSIONE_ORDINE_MEDIA = 'MEDIA';
export type DIMENSIONE_ORDINE_GRANDE = 'GRANDE';
export type DIMENSIONE_ORDINE = DIMENSIONE_ORDINE_PICCOLA | DIMENSIONE_ORDINE_MEDIA | DIMENSIONE_ORDINE_GRANDE ;

export const METODO_DI_PAGAMENTO_ORDINE_STRIPE = 'STRIPE';
export const METODO_DI_PAGAMENTO_ORDINE_PAYPAL = 'PAYPAL';
export const METODO_DI_PAGAMENTO_ORDINE_CONTANTI = 'CONTANTI';
export type METODO_DI_PAGAMENTO_ORDINE_STRIPE = 'STRIPE';
export type METODO_DI_PAGAMENTO_ORDINE_PAYPAL = 'PAYPAL';
export type METODO_DI_PAGAMENTO_ORDINE_CONTANTI = 'CONTANTI';
export type METODO_DI_PAGAMENTO_ORDINE = METODO_DI_PAGAMENTO_ORDINE_STRIPE | METODO_DI_PAGAMENTO_ORDINE_PAYPAL | METODO_DI_PAGAMENTO_ORDINE_CONTANTI ;

export type STATO_ORDINE_NONPROCESSATO = 'NonProcessato';
export type STATO_ORDINE_INATTESA = 'InAttesa';
export type STATO_ORDINE_NUOVO = 'Nuovo';
export type STATO_ORDINE_CONFERMATO = 'Confermato';
export type STATO_ORDINE_INRITIRO = 'InRitiro';
export type STATO_ORDINE_RITIRATO = 'Ritirato';
export type STATO_ORDINE_CONSEGNATO = 'Consegnato';
export type STATO_ORDINE_ANNULLATO = 'Annullato';
export type STATO_ORDINE_RIFIUTATO = 'Rifiutato';

export const STATO_ORDINE_NONPROCESSATO = 'NonProcessato';
export const STATO_ORDINE_INATTESA = 'InAttesa';
export const STATO_ORDINE_NUOVO = 'Nuovo';
export const STATO_ORDINE_CONFERMATO = 'Confermato';
export const STATO_ORDINE_INRITIRO = 'InRitiro';
export const STATO_ORDINE_RITIRATO = 'Ritirato';
export const STATO_ORDINE_CONSEGNATO = 'Consegnato';
export const STATO_ORDINE_ANNULLATO = 'Annullato';
export const STATO_ORDINE_RIFIUTATO = 'Rifiutato';

export type ORDINE_STATO_TYPE =
  STATO_ORDINE_NONPROCESSATO |
  STATO_ORDINE_INATTESA |
  STATO_ORDINE_NUOVO |
  STATO_ORDINE_CONFERMATO |
  STATO_ORDINE_INRITIRO |
  STATO_ORDINE_RITIRATO |
  STATO_ORDINE_CONSEGNATO |
  STATO_ORDINE_ANNULLATO |
  STATO_ORDINE_RIFIUTATO;

export const enum STATOINT_ORDINE {
  STATO_ORDINE_NONPROCESSATO = -2,
  STATO_ORDINE_INATTESA = -1,
  STATO_ORDINE_NUOVO = 0,
  STATO_ORDINE_CONFERMATO = 1,
  STATO_ORDINE_INRITIRO = 2,
  STATO_ORDINE_RITIRATO = 3,
  STATO_ORDINE_CONSEGNATO = 4,
  STATO_ORDINE_ANNULLATO = 5,
  STATO_ORDINE_RIFIUTATO = 6
}

export type ORDINE_STATOINT_TYPE =
  STATOINT_ORDINE.STATO_ORDINE_NONPROCESSATO |
  STATOINT_ORDINE.STATO_ORDINE_INATTESA |
  STATOINT_ORDINE.STATO_ORDINE_NUOVO |
  STATOINT_ORDINE.STATO_ORDINE_CONFERMATO |
  STATOINT_ORDINE.STATO_ORDINE_INRITIRO |
  STATOINT_ORDINE.STATO_ORDINE_RITIRATO |
  STATOINT_ORDINE.STATO_ORDINE_CONSEGNATO |
  STATOINT_ORDINE.STATO_ORDINE_RIFIUTATO;


export interface Ordine {
  id: string;
  TipoOrdine: TIPO_ORDINE;
  DataInserimento: string;
  ID_Attivita: string;
  CodiceAttivita: string;
  RiferimentoAttivita: string;
  ID_Utente: string;
  RiferimentoUtente: string;
  CodicePosizioneUtente: string;
  DataConsegna: string;
  ConsegnaPrimaPossibile: boolean;
  OrarioRichiesto: string;
  OrarioRitiro?: string;
  FasciaOraria?: string;
  Indirizzo: string;
  NotaIndirizzo?: string;
  Citofono?: string;
  Latitudine?: number;
  Longitudine?: number;
  Dimensione: DIMENSIONE_ORDINE;
  CodiceConsegna?: string;
  Stato: ORDINE_STATO_TYPE;
  StatoInt?: ORDINE_STATOINT_TYPE;
  Nota?: string;
  MetodoDiPagamento?: METODO_DI_PAGAMENTO_ORDINE;
  StatoPagamento?: boolean;
  ID_UserRider?: string;
  RiferimentoRider?: string;
  ID_MTTask?: any;
  NomeDB: string;
  ID_Menu?: string;
  ElencoProdotti?: ProdottoOrdine[];
  TotaleProdotti?: number;
  TotaleOrdine?: number;
  CostoConsegnaDelivery?: number;
  TotaleNumProdotti?: number;
  NomeImgLogoAttivita?: string;
}

export interface ProdottoOrdine {
  id: string;
  IdMenu?: string;
  IdCategoriaMenu: string;
  IdProdottoMenu: string;
  Nome: string;
  Descrizione: string;
  Ordine?: number;
  Prezzo: number;
  Quantita: number;
  PrezzoTotale?: number;
}

export const StatiOrdine = [
  {
    Nome: STATO_ORDINE_NONPROCESSATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_NONPROCESSATO,
  },
  {
    Nome: STATO_ORDINE_INATTESA,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_INATTESA,
  },
  {
    Nome: STATO_ORDINE_NUOVO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_NUOVO,
  },
  {
    Nome: STATO_ORDINE_CONFERMATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_CONFERMATO,
  },
  {
    Nome: STATO_ORDINE_INRITIRO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_INRITIRO,
  },
  {
    Nome: STATO_ORDINE_RITIRATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_RITIRATO,
  },
  {
    Nome: STATO_ORDINE_CONSEGNATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_CONSEGNATO,
  },
  {
    Nome: STATO_ORDINE_ANNULLATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_ANNULLATO,
  },
  {
    Nome: STATO_ORDINE_RIFIUTATO,
    Ordine: STATOINT_ORDINE.STATO_ORDINE_RIFIUTATO,
  }
];
