import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {ProdottoCart} from '../../../models/cart.model';
import {HapticsService} from '../../services/haptics.service';

@Component({
  selector: 'app-edit-product-cart-modal',
  templateUrl: './edit-product-cart-modal-page.component.html',
  styleUrls: ['./edit-product-cart-modal-page.component.scss'],
})
export class EditProductCartModalPage implements OnInit {

  @Input('prodotto') prodotto: ProdottoCart;
  @Input('title') title = 'Modifica Prodotto';

  quantitaIniziale = 0;

  constructor(
    public element: ElementRef,
    private modalCtrl: ModalController,
    private hapticsService: HapticsService) {
  }

  ngOnInit() {
    this.quantitaIniziale = this.prodotto.Quantita;
  }

  dismiss() {
    this.modalCtrl.dismiss(null);
  }

  conferma() {
    this.hapticsService.hapticPress();
    this.setProduct();
  }

  elimina() {
    this.hapticsService.hapticPress();
    this.prodotto = {
      ...this.prodotto,
      Quantita: 0
    };
    this.setProduct();
  }

  setProduct() {
    this.modalCtrl.dismiss({prodotto: this.prodotto});
  }

  subOne() {
    this.hapticsService.hapticPress();
    if (this.prodotto.Quantita > 0) {
      this.prodotto = {
        ...this.prodotto,
        Quantita: this.prodotto.Quantita - 1
      };
    }
  }

  addOne() {
    this.hapticsService.hapticPress();
    this.prodotto = {
      ...this.prodotto,
      Quantita: this.prodotto.Quantita + 1
    };
  }

}
