import {createAction, props} from '@ngrx/store';

export const LoadAreeConsegna = createAction(
  '[Configs] Load Aree Consegna');

export const LoadAreeConsegnaSuccess = createAction(
  '[Configs] Load Aree Consegna Success',
  props<{ areeconsegna: any[] }>());

export const LoadAreeConsegnaError = createAction(
  '[Configs] Load Aree Consegna Error',
  props<{ error: any }>());




