import {createReducer, on, Action} from '@ngrx/store';
import {AuthActions} from '../actions';

export interface UserAuthData {
  id: string;
  username: string;
  role: string;
  RagioneSociale?: string;
  Email?: string;
  UltimoLogin: string;
  token: string;
  stato: number;
  Codice?: string;
  PushToken?: string;
}

export interface AuthState {
  loading: boolean;
  isAuthenticated: boolean;
  token: string;
  pushToken: string;
  userAuthData: UserAuthData;
  formDataLogin: LoginFormData;
  prevUrl: string;
}

export interface LoginFormData {
  username: string;
  password: string;
  memorizzaCredenziali: boolean;
}

/*
 INITIAL STATES
 */

export const initialUserAuthData: UserAuthData = {
  id: null,
  username: null,
  role: null,
  RagioneSociale: null,
  Email: null,
  stato: 0,
  Codice: null,
  token: null,
  UltimoLogin: null
};

export const initiaFormDataLogin: LoginFormData = {
  username: '',
  password: '',
  memorizzaCredenziali: true
};

export const initialState: AuthState = {
  loading: false,
  isAuthenticated: false,
  token: null,
  pushToken: null,
  userAuthData: initialUserAuthData,
  formDataLogin: initiaFormDataLogin,
  prevUrl: '/home'
};

const reducer = createReducer(
  initialState,
  on(AuthActions.Login, (state) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.LoginSuccess, (state, {userAuthData}) => ({
    ...state,
    loading: false,
    isAuthenticated: true,
    token: userAuthData.token,
    pushToken: userAuthData.PushToken,
    userAuthData
  })),
  on(AuthActions.LoginError, (state) => ({
    ...state,
    loading: false
  })),

  on(AuthActions.SignIn, (state) => ({
    ...state,
    loading: true
  })),
  on(AuthActions.SignInSuccess, (state, {userData}) => ({
    ...state,
    loading: false,
  })),
  on(AuthActions.SignInError, (state) => ({
    ...state,
    loading: false
  })),

  on(AuthActions.MemorizzaCredenzialiLogin, (state, {formDataLogin}) => ({
    ...state,
    formDataLogin
  })),

  on(AuthActions.SetPrevUrl, (state, {url}) => ({
    ...state,
    prevUrl: url
  })),

  /*on(AuthActions.SetTokenNotifiche, (state, {tokenNotifiche}) => ({
      ...state,
      tokenNotifiche
  })),*/

  on(AuthActions.Logout, state => ({
    ...state,
    loading: false,
    isAuthenticated: false,
    userAuthData: initialUserAuthData
  }))
);

export function authReducer(
  state: AuthState | undefined,
  action: Action
): AuthState {
  return reducer(state, action);
}
