import { createSelector } from '@ngrx/store';

import {selectAuthState} from '../../../core.state';
import {AuthState} from '../reducers/auth.reducer';

export const SelectAuthUserData = createSelector(
    selectAuthState,
    (state: AuthState) => state.userAuthData
);

export const SelectToken = createSelector(
  selectAuthState,
  (state: AuthState) => state.token
);

export const SelectPrevUrl = createSelector(
  selectAuthState,
  (state: AuthState) => state.prevUrl
);

export const SelectTokenNotifichePush = createSelector(
  selectAuthState,
    (state: AuthState) => state.pushToken
);

export const SelectFormDataLogin = createSelector(
    selectAuthState,
    (state: AuthState) => state.formDataLogin
);

export const SelectIsLoading = createSelector(
    selectAuthState,
    (state: AuthState) => state.loading
);

export const SelectIsAuthenticated = createSelector(
    selectAuthState,
  (state: AuthState) => state.isAuthenticated
);

