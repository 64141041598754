import {Injectable, ErrorHandler, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {environment as ENV} from '../../../environments/environment';

import {NotificationService} from '../../shared/services/notification.service';

import {
  AppError,
  APPERROR_NO_USER_CONFIGS_DATA
} from './app-error';
import {Router} from '@angular/router';
import {Capacitor} from '@capacitor/core';

@Injectable()
export class AppErrorHandler extends ErrorHandler {

  private notificationsService;
  private router;

  constructor(private injector: Injector) {
    super();
  }

  handleError(error: Error | HttpErrorResponse) {

    this.notificationsService = this.injector.get(NotificationService);
    this.router = this.injector.get(Router);

    if (error instanceof HttpErrorResponse) {
      // this.WebServiceErrorHandler(error.error);
      return;
    }

    console.log('Errore', error);

    if (error instanceof AppError) {
      this.AppErrorHandler(error);
      return;
    }

    let displayMessage = 'Si è verificato un errore';

    if (!ENV.production) {
      displayMessage += ' Dettaglio in console.';
      this.notificationsService.danger(displayMessage);
    }

    super.handleError(error);
  }

  /**
   * Funzione per la gestione degli errori dell APP
   */
  private AppErrorHandler(err: AppError) {

    switch (err.errorCode) {
      case APPERROR_NO_USER_CONFIGS_DATA:
        this.notificationsService.danger(err.message);
        break;
      default:
        this.notificationsService.danger(err.message);
        if (!ENV.production) {
          console.error(err);
        }
        break;
    }
  }

}
