import {Injectable} from '@angular/core';
import {ToastController} from '@ionic/angular';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    dismissedToast: boolean;

    constructor(
        private readonly toastController: ToastController
    ) {
        this.dismissedToast = true;
    }

    default(message: string, duration: number = 2000, otherConfig: object = {}) {
        this.presentToast(message, 'primary', duration, otherConfig);
    }

    info(message: string, duration: number = 2000, otherConfig: object = {}) {
        this.presentToast(message, 'secondary', duration, otherConfig);
    }

    success(message: string, duration: number = 2000, otherConfig: object = {}) {
        this.presentToast(message, 'success', duration, otherConfig);
    }

    warning(message: string, duration: number = 2000, otherConfig: object = {}) {
        this.presentNoMultipleToast(message, 'warning', duration, otherConfig);
    }

    danger(message: string, duration: number = 2000, otherConfig: object = {}) {
        this.presentNoMultipleToast(message, 'danger', duration, otherConfig);
    }

    // -------------------------------------------------------------------------------------------------------------------------------------

    /*{     showCloseButton: true,
            closeButtonText: 'Chiudi'
            }*/

    private presentToast(message: string, color: string, duration: number = 2000, otherConfig: object = {}) {

        const config = {
            message,
            color,
            duration,
            ...otherConfig
        };

        this.toastController.create(config)
            .then((t) => {
                this.dismissedToast = false;
                t.present().then(null);
                t.onWillDismiss().then(() => {
                    this.dismissedToast = true;
                });
            });
    }

    private presentNoMultipleToast(message: string, color: string, duration: number = 2000, otherConfig: object = {}) {
        if (!this.dismissedToast) {
            return;
        }

        this.presentToast(message, color, duration, otherConfig);
    }

}
