import {createSelector} from '@ngrx/store';
import {Params} from '@angular/router';

import * as moment from 'moment';

import {selectCartState} from '../../../core.state';
import {CartState, TotaliCarrello} from '../reducers/cart.reducer';
import {OrdineMininoInfo} from '../../../../models/cart.model';
import {selectUrl} from 'src/app/core/router/route.selectors';
import {SelectCurrentUrlParams} from '../../../router/router.state';
import {Attivita} from '../../../../models/attivita.model';
import {DIMENSIONE_ORDINE_GRANDE, DIMENSIONE_ORDINE_MEDIA, DIMENSIONE_ORDINE_PICCOLA} from '../../../../models/ordini.model';
import {FasceOrarieUtility} from '../../../../shared/utility/fasce-orarie.utility';

export const SelectLoading = createSelector(
  selectCartState,
  (state: CartState) => state.loading
);

export const SelectAttivitaOrdine = createSelector(
  selectCartState,
  (state: CartState) => state.attivita
);

export const SelectIsPreordine = createSelector(
  selectCartState,
  (state: CartState) => state.isPreordine
);

export const SelectOrdine = createSelector(
  selectCartState,
  (state: CartState) => state.ordine
);

export const SelectOrdineDaPagare = createSelector(
  selectCartState,
  (state: CartState) => state.ordineDaPagare
);

export const SelectProdottiOrdine = createSelector(
  selectCartState,
  (state: CartState) => state.elencoProdotti
);

export const SelectTotaliOrdine = createSelector(
  selectCartState,
  (state: CartState) => state.totali
);

export const SelectTotProductsInCart = createSelector(
  selectCartState,
  (state: CartState) => state.elencoProdotti.reduce((tot, prodottoInCart) => (tot += prodottoInCart.Quantita), 0)
);

export const SelectStatoOrdineMinimo = createSelector(
  selectCartState,
  SelectTotProductsInCart,
  SelectTotaliOrdine,
  (state: CartState, NumTotaleProdotti: number, totaliCarrello: TotaliCarrello) => ({
    OrdineMinimoSuperato: (state.attivita?.OrdineMinimo <= 0 || (state.totali?.TotaleProdotti >= state.attivita?.OrdineMinimo)),
    DifferenzaOrdineMinimo: (state.attivita?.OrdineMinimo - state.totali?.TotaleProdotti),
    TotaleProdotti: totaliCarrello?.TotaleProdotti,
    NumTotaleProdotti
  }) as OrdineMininoInfo
);

export const SelectIfShowStatoOrdineMinimoBlock = createSelector(
  SelectStatoOrdineMinimo,
  SelectAttivitaOrdine,
  selectUrl,
  SelectCurrentUrlParams,
  (stato: OrdineMininoInfo, attivitaOrdine: Attivita, url: string, routeParams: Params) => {
    return (url?.includes('/home/detail') && attivitaOrdine?.id === routeParams?.id && stato?.NumTotaleProdotti > 0);
  }
);

/**
 * deprecato perchè sostituito da SelectOrariDiConsegnaPickerByFasciaAttiva
 * @deprecated
 */
export const SelectOrariDiConsegnaPickerValue = createSelector(
  SelectAttivitaOrdine,
  (attivita) => {

    if (!attivita?.FasceOrarie) {
      return [];
    }

    const {FasceOrarie} = attivita;

    const timeStepInMinute = 15;
    const now = moment().locale('it');
    const pickerData = [];
    [now.clone(), now.clone().add(1, 'day')].forEach((data) => {

      let giorno = data.clone().locale('it').format('dddd').replace('ì', 'i');
      giorno = giorno.charAt(0).toUpperCase() + giorno.slice(1);

      FasceOrarie.filter((fascia) => fascia[giorno]).forEach((fascia) => {

        const f = fascia.Orario.split('-');
        const oraStart = moment(data.format('YYYY-MM-DD') + ' ' + f[0], 'YYYY-MM-DD HH:mm').locale('it');
        const oraEnd = moment(data.format('YYYY-MM-DD') + ' ' + f[1], 'YYYY-MM-DD HH:mm').locale('it');

        let timeStart = f[0];
        let textDayString = 'Oggi';

        // Se è il giorno odierno parto dall'ora attuale in poi al prossimo quarto d'ora
        if (giorno.toLowerCase() === now.clone().format('dddd').replace('ì', 'i')) {
          const remainder = timeStepInMinute - (now.clone().minute() % timeStepInMinute);
          timeStart = now.clone().add(remainder, 'minutes').add(timeStepInMinute, 'minutes').format('HH:mm');
        } else {
          textDayString = 'Domani';
        }

        const timeStartCiclo = moment(data.format('YYYY-MM-DD') + ' ' + timeStart, 'YYYY-MM-DD HH:mm').locale('it');
        while (timeStartCiclo.isBefore(oraEnd, 'minutes')) {
          if (timeStartCiclo.isBetween(oraStart, oraEnd, 'minute', '[]')) {
            pickerData.push({
              text: textDayString + ' ' + timeStartCiclo.clone().format('HH:mm'),
              value: timeStartCiclo.clone().format('YYYY-MM-DD HH:mm')
            });
          }
          timeStartCiclo.add(timeStepInMinute, 'minutes');
        }
      });

    });

    return pickerData;
  }
);

export const SelectOrariDiConsegnaPickerByFasciaAttiva = createSelector(
  SelectAttivitaOrdine,
  (attivita) => {

    if (!attivita?.InfoTemporaliAttivita.ArcoTemporale) {
      return [];
    }

    const f = attivita.InfoTemporaliAttivita.ArcoTemporale.split('-');
    const data = moment(attivita.InfoTemporaliAttivita.Data).clone().locale('it');

    const giorno = FasceOrarieUtility.getGiornoData(data);
    const giornoOdierno = FasceOrarieUtility.getGiornoData(moment().locale('it'));

    const oraStart = moment(data.format('YYYY-MM-DD') + ' ' + f[0], 'YYYY-MM-DD HH:mm').locale('it');
    const oraEnd = moment(data.format('YYYY-MM-DD') + ' ' + f[1], 'YYYY-MM-DD HH:mm').locale('it');

    const timeStepInMinute = 15;
    const now = moment().locale('it');
    const pickerData = [];

    let timeStart = f[0];
    let textDayString = 'Oggi';

    // Se è il giorno odierno parto dall'ora attuale in poi al prossimo quarto d'ora
    if (giorno.toLowerCase() === giornoOdierno.toLowerCase()) {
      const remainder = timeStepInMinute - (now.clone().minute() % timeStepInMinute);
      timeStart = now.clone().add(remainder, 'minutes').add(timeStepInMinute, 'minutes').format('HH:mm');
    } else {
      textDayString = 'Domani';
    }

    const timeStartCiclo = moment(data.format('YYYY-MM-DD') + ' ' + timeStart, 'YYYY-MM-DD HH:mm').locale('it');
    while (timeStartCiclo.isBefore(oraEnd, 'minutes')) {
      if (timeStartCiclo.isBetween(oraStart, oraEnd, 'minute', '[]')) {
        pickerData.push({
          text: textDayString + ' ' + timeStartCiclo.clone().format('HH:mm'),
          value: timeStartCiclo.clone().format('YYYY-MM-DD HH:mm')
        });
      }
      timeStartCiclo.add(timeStepInMinute, 'minutes');
    }

    return pickerData;
  }
);

export const SelectDimensioneOrdine = createSelector(
  SelectTotProductsInCart,
  (totProductsInCart: number) => {
    switch (true) {
      case totProductsInCart >= 5:
        return DIMENSIONE_ORDINE_GRANDE;
      case totProductsInCart >= 3 && totProductsInCart < 5:
        return DIMENSIONE_ORDINE_MEDIA;
      default:
        return DIMENSIONE_ORDINE_PICCOLA;
    }
  }
);

